import {
    arrow,
    autoUpdate,
    flip,
    offset,
    type Placement,
    shift,
    useDismiss,
    useFloating,
    type UseFloatingReturn,
    useFocus,
    useHover,
    useInteractions,
    useRole,
} from '@floating-ui/react';
import { useRef, useState } from 'react';

export interface TooltipOptions {
    placement?: Placement;
    interactive?: boolean;
}

export const useTooltip = ({ placement = 'top', interactive }: TooltipOptions = {}) => {
    const [open, setOpen] = useState(false);
    const arrowRef = useRef(null);

    const data = useFloating({
        placement,
        open,
        onOpenChange: setOpen,
        whileElementsMounted: autoUpdate,
        middleware: [
            offset(8),
            flip(),
            shift({ padding: 4 }),
            arrow({
                element: arrowRef,
                padding: 8,
            }),
        ],
    });

    const context = data.context;

    const hover = useHover(context, {
        move: false,
        delay: {
            close: interactive ? 150 : 0,
            open: 200,
        },
    });
    const focus = useFocus(context);
    const dismiss = useDismiss(context);
    const role = useRole(context, { role: 'tooltip' });

    const interactions = useInteractions([hover, focus, dismiss, role]);

    return {
        open,
        setOpen,
        interactions,
        data: data as UseFloatingReturn,
        arrowRef,
    };
};

export type TooltipState = ReturnType<typeof useTooltip>;
