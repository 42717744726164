import React, { FC, useMemo } from 'react';

import { useTrCountry } from '@hofy/i18n';
import { Color } from '@hofy/theme';
import { Box, CountryFlag, FeaturedIcon, Paragraph3, SvgIcon, Tooltip } from '@hofy/ui';

import { ShipmentPoint, ShipmentPointType } from '../../../store/shipments/types/ShipmentPoint';
import { AddressTooltipOverlay } from '../address/AddressTooltipOverlay';

interface ShipmentPointCardProps {
    point: ShipmentPoint;
    isDelivery?: boolean;
}

export const ShipmentPointCard: FC<ShipmentPointCardProps> = ({ point, isDelivery = false }) => {
    const trCountry = useTrCountry();

    const label = () => {
        switch (point.type) {
            case ShipmentPointType.Address:
                return <Paragraph3>{trCountry(point.address.country)}</Paragraph3>;
            case ShipmentPointType.HofyWarehouse:
                return <Paragraph3>{point.warehouse?.name ?? 'Hofy warehouse'}</Paragraph3>;
            case ShipmentPointType.ClearanceWarehouse:
                return <Paragraph3>{point.warehouse?.name ?? 'Clearance warehouse'}</Paragraph3>;
            case ShipmentPointType.Supplier:
                return <Paragraph3>{point.supplier?.name ?? 'Supplier'}</Paragraph3>;
            default:
                point satisfies never;
                return null;
        }
    };

    const indicator = () => {
        switch (point.type) {
            case ShipmentPointType.Address:
                return <CountryFlag country={point.address.country} size={32} />;
            case ShipmentPointType.HofyWarehouse:
                return <FeaturedIcon icon={SvgIcon.Hofy} shape='circle' size={32} variant='vivid' />;
            case ShipmentPointType.ClearanceWarehouse:
                return <FeaturedIcon icon={SvgIcon.DollarSign} shape='circle' size={32} variant='vivid' />;
            case ShipmentPointType.Supplier:
                return (
                    <FeaturedIcon
                        icon={SvgIcon.Box}
                        shape='circle'
                        size={32}
                        variant='vivid'
                        type='warning'
                    />
                );
            default:
                point satisfies never;
                return null;
        }
    };

    const card = () => {
        return (
            <Box row gap={10}>
                {indicator()}
                <Box>
                    <Paragraph3 color={Color.ContentTertiary}>{`${isDelivery ? 'to' : 'from'}:`}</Paragraph3>
                    {label()}
                </Box>
            </Box>
        );
    };

    const address = useMemo(() => {
        if (point.type === ShipmentPointType.Address) {
            return point.address;
        }

        return null;
    }, [point]);

    if (!address) {
        return <>{card()}</>;
    }

    return (
        <Tooltip
            placement='left'
            bodySlot={<AddressTooltipOverlay address={address} />}
            maxWidth='auto'
            interactive
        >
            {card()}
        </Tooltip>
    );
};
