import React, { FC } from 'react';

import { useInvoiceQuery } from '@hofy/api-admin';
import { Overlay } from '@hofy/common';
import { UUID } from '@hofy/global';
import { formatDateTime } from '@hofy/helpers';
import { Color } from '@hofy/theme';
import { Alert, Box, PageHeader, Skeleton, SvgIcon, Tab, Tabs } from '@hofy/ui';
import { InvoiceStatusChip } from '@hofy/ui-domain';

import { getInvoiceDetailsTabs, InvoiceDetailTabs } from '../../../../store/invoices/types/InvoiceDetailTabs';
import { useTrInvoiceDetailsTab } from '../../../../store/invoices/useTrInvoiceDetailsTab';
import { AppliedTransactionsTab } from './AppliedTransactionsTab';
import { InvoiceDetailsPageMenu } from './InvoiceDetailsPageMenu';
import { InvoiceDetailsTab } from './InvoiceDetailsTab';
import { InvoiceEntriesTab } from './InvoiceEntriesTab';
import { PaymentDetailsTab } from './PaymentDetailsTab';

interface InvoiceOverlayPageProps {
    invoiceId: UUID;
    tab: InvoiceDetailTabs;
    onChangeTab(v: InvoiceDetailTabs): void;
    onOpenInvoice(id: UUID): void;
}

export const InvoiceDetailsOverlay: FC<InvoiceOverlayPageProps> = ({
    invoiceId,
    onChangeTab,
    tab,
    onOpenInvoice,
}) => {
    const trTab = useTrInvoiceDetailsTab();
    const { data: invoice, isLoading } = useInvoiceQuery(invoiceId);
    const excludedFromDunning = invoice?.excludedFromDunning ?? false;

    if (isLoading || !invoice) {
        return <Skeleton />;
    }

    const renderContent = () => {
        switch (tab) {
            case InvoiceDetailTabs.Details:
                return <InvoiceDetailsTab invoice={invoice} />;

            case InvoiceDetailTabs.InvoiceEntries:
                return <InvoiceEntriesTab invoiceId={invoiceId} />;

            case InvoiceDetailTabs.PaymentDetails:
                return <PaymentDetailsTab invoiceId={invoiceId} />;

            case InvoiceDetailTabs.AppliedCreditNotes:
                return (
                    <AppliedTransactionsTab
                        transactions={invoice.appliedCreditNotes}
                        onOpenInvoice={onOpenInvoice}
                        isCreditNote
                    />
                );

            case InvoiceDetailTabs.AppliedInvoices:
                return (
                    <AppliedTransactionsTab
                        transactions={invoice.appliedInvoices}
                        onOpenInvoice={onOpenInvoice}
                        isCreditNote={false}
                    />
                );
        }
    };

    const isDeel = invoice.deel.status !== null;
    return (
        <Overlay column flex='auto' bg={Color.BackgroundDefault}>
            {!invoice.twoApi && excludedFromDunning && (
                <Alert
                    type='warning'
                    icon={SvgIcon.AlertTriangle}
                    title='Invoice excluded from payment reminders'
                    description={`
                          This invoice has been excluded from payment reminders on ${formatDateTime(
                              excludedFromDunning!.at,
                          )} by ${invoice.excludedFromDunning!.by.name}.
                          The reason for exclusion is: ${invoice.excludedFromDunning!.reason}.
                      `}
                />
            )}
            <PageHeader
                title={`${isDeel ? 'Deel' : ''} ${!invoice.isCreditNote ? 'Invoice' : 'Credit note'} ${invoice.reference ?? '(pending submission)'}`}
                titleSlot={
                    <InvoiceStatusChip
                        size='large'
                        variant='vivid'
                        status={invoice.status}
                        isCreditNote={invoice.isCreditNote}
                    />
                }
                rightSlot={<InvoiceDetailsPageMenu invoice={invoice} />}
                tabsSlot={
                    <Tabs flex='auto' active={tab} onChange={onChangeTab}>
                        {getInvoiceDetailsTabs(invoice.isCreditNote).map(item => (
                            <Tab key={item} id={item} label={trTab(item)} />
                        ))}
                    </Tabs>
                }
            />
            <Box flex='auto' relative>
                {renderContent()}
            </Box>
        </Overlay>
    );
};
