import React, { FC } from 'react';

import { DeelStatusDto } from '@hofy/api-admin';
import { formatDateTime } from '@hofy/helpers';
import { Box, FormGridRow, FormSection, Icon, Labeled, LabeledLink, LabeledText, SvgIcon } from '@hofy/ui';

import { DeelStatusChip } from '../../../../../components/domain/invoices/DeelStatusChip';
import { Link } from '../../../../../components/routing/Link';

interface InvoiceDeelSectionProps {
    deel: DeelStatusDto;
    invoiceLink: string | null;
}

export const InvoiceDeelSection: FC<InvoiceDeelSectionProps> = ({ deel, invoiceLink }) => {
    if (!deel.status) {
        return;
    }
    return (
        <FormSection label='Deel'>
            <Box row>
                <Box column flex={4} rowGap={24}>
                    <FormGridRow columns={3}>
                        <Labeled label='Status' content={<DeelStatusChip status={deel} />} />
                        {deel.successAt && (
                            <LabeledText label='Success at' content={formatDateTime(deel.successAt)} />
                        )}
                        {deel.errorAt && (
                            <LabeledText label='Error at' content={formatDateTime(deel.errorAt)} />
                        )}
                        {deel.errorReason && <LabeledText label='Error reason' content={deel.errorReason} />}
                        {invoiceLink && (
                            <LabeledLink
                                label='Deel ID'
                                content={
                                    <Link flex to={invoiceLink}>
                                        <Icon size={14} svg={SvgIcon.File} marginRight={4} />
                                        {deel.id}
                                    </Link>
                                }
                            />
                        )}
                    </FormGridRow>
                </Box>
                <Box row flex={1} minWidth='200px' />
            </Box>
        </FormSection>
    );
};
