import React, { FC } from 'react';

import { emptyAdminInvoiceFilters, useInvoices } from '@hofy/api-admin';
import { UUID } from '@hofy/global';

import { InvoicesTable } from '../../../../components/domain/invoices/InvoicesTable';

interface BillingEntityInvoicesTableProps {
    billingEntityId: UUID;
    onInvoiceClick(invoiceId: UUID): void;
}

export const BillingEntityInvoicesTable: FC<BillingEntityInvoicesTableProps> = ({
    billingEntityId,
    onInvoiceClick,
}) => {
    const { invoices, isFetchingNextPage, isLoading, hasNextPage, fetchNextPage } = useInvoices({
        ...emptyAdminInvoiceFilters,
        billingEntity: [billingEntityId],
    });

    return (
        <InvoicesTable
            infinityScroll={{
                hasMore: hasNextPage,
                isLoading: isLoading,
                isLoadingMore: isFetchingNextPage,
                loadMore: fetchNextPage,
            }}
            invoices={invoices}
            onEntryClick={onInvoiceClick}
        />
    );
};
