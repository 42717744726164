import React, { FC, useEffect } from 'react';

import { arePostAddressesEqual } from '@hofy/address';
import { UserAssignmentDto } from '@hofy/api-admin';
import {
    AssignmentCollectionReason,
    Permission,
    ShipmentStatus,
    shipmentStatusNotificationType,
    ShipmentType,
    shipmentTypeAssignmentStatus,
} from '@hofy/api-shared';
import { SlideoutContent, SlideoutFooter, SlideoutHeader } from '@hofy/common';
import { Country, UUID } from '@hofy/global';
import { usePermission } from '@hofy/permission';
import { Alert, AsyncButton, Button, FormSection, LabeledText, SvgIcon, UseForm } from '@hofy/ui';

import { AddressDetails } from '../../../components/domain/address/AddressDetails';
import { AdminListDropdown } from '../../../components/domain/user/AdminListDropdown';
import { FormClearanceWarehouseSelect } from '../../../components/domain/warehouses/FormClearanceWarehouseSelect';
import { FormHofyWarehouseSelect } from '../../../components/domain/warehouses/FormHofyWarehouseSelect';
import {
    CreateCollectionShipmentFormData,
    useCreateCollectionShipment,
} from '../../../store/shipments/useCreateShipment';
import { LabeledUserLink } from '../../peoplePage/users/UserLink';
import { SendCollectionSurveyForm } from '../components/SendCollectionSurveyForm';
import { AssignmentSelection } from './AssignmentSelection';

interface CreateCollectionShipmentFormProps {
    userId: UUID;
    selected: UserAssignmentDto[];
    country: Country;
    onSuccess(id: UUID): void;
    onClose(): void;
}

export const CreateCollectionShipmentForm: FC<CreateCollectionShipmentFormProps> = ({
    onSuccess,
    onClose,
    selected,
    userId,
    country,
}) => {
    const { hasPermission } = usePermission();
    const canSendSurveyForm = hasPermission(Permission.AdminCollectionRequestSendSurvey);

    const { selection, isLoadingMutation, form } = useCreateCollectionShipment(selected, onSuccess, country);

    const firstItemCollectionToOrgWarehouse = selected[0].collectionToOrganizationWarehouse;
    const primaryManager = firstItemCollectionToOrgWarehouse?.organizationWarehouse?.primaryManager;
    const secondaryManager = firstItemCollectionToOrgWarehouse?.organizationWarehouse?.secondaryManager;

    useEffect(() => {
        if (firstItemCollectionToOrgWarehouse) {
            form.setValues({
                warehouseId: firstItemCollectionToOrgWarehouse?.id,
            });
        }
    }, [firstItemCollectionToOrgWarehouse]);

    const warehouseSelect = getWarehouseSelectForAssignmentCollectionReason(
        form,
        selected[0].collectionReason,
    );

    return (
        <>
            <SlideoutHeader title='Create shipment' />
            <SlideoutContent column gap={24} paddingVertical={24}>
                <Alert
                    type={shipmentStatusNotificationType[ShipmentStatus.Created]}
                    description='Creating shipment'
                />
                {firstItemCollectionToOrgWarehouse ? (
                    <FormSection label='Transfer to storage location'>
                        <LabeledText label='Name' content={firstItemCollectionToOrgWarehouse.name} />
                        <LabeledUserLink user={primaryManager || null} label='Storage primary manager' />
                        <LabeledUserLink user={secondaryManager || null} label='Storage secondary manager' />
                        <AddressDetails address={firstItemCollectionToOrgWarehouse.address} />
                    </FormSection>
                ) : (
                    <FormSection label='Create collection shipment'>{warehouseSelect}</FormSection>
                )}
                <AdminListDropdown
                    label='Assigned user'
                    emptyContent='Pick user'
                    value={form.values.assignedUserId ?? undefined}
                    onChange={assignedUserId =>
                        form.setValues({
                            assignedUserId,
                        })
                    }
                />
                <AssignmentSelection
                    status={shipmentTypeAssignmentStatus[ShipmentType.Collection]}
                    selection={selection}
                    userId={userId}
                    filterAdditionalAssignmentsBy={assignment =>
                        arePostAddressesEqual(
                            assignment.collectionToOrganizationWarehouse?.address,
                            firstItemCollectionToOrgWarehouse?.address,
                        )
                    }
                />
                {canSendSurveyForm && <SendCollectionSurveyForm showSendOption form={form} />}
            </SlideoutContent>
            <SlideoutFooter>
                <Button
                    type='ghost'
                    negativeMargin
                    onClick={onClose}
                    label='Close'
                    leftIcon={SvgIcon.Cross}
                />
                <AsyncButton
                    isLoading={isLoadingMutation}
                    label='Create'
                    onClick={form.submit}
                    disableCheck
                />
            </SlideoutFooter>
        </>
    );
};

const getWarehouseSelectForAssignmentCollectionReason = (
    form: UseForm<CreateCollectionShipmentFormData>,
    collectionReason: AssignmentCollectionReason | null,
) => {
    if (collectionReason === AssignmentCollectionReason.Clearance) {
        return (
            <FormClearanceWarehouseSelect
                label='To warehouse'
                api={form.fields.warehouseId}
                isRequired
                nullable
            />
        );
    }
    return <FormHofyWarehouseSelect label='To warehouse' api={form.fields.warehouseId} isRequired nullable />;
};
