import enGb from 'date-fns/locale/en-GB';
import React, { ComponentClass, FC } from 'react';
import DatePicker, { type ReactDatePickerProps, registerLocale } from 'react-datepicker';
import styled from 'styled-components';

import { Color, fontFamily } from '@hofy/theme';

const locale = 'en-gb';
registerLocale(locale, enGb);

const StyledWrapper: FC<ReactDatePickerProps> = ({ className, ...props }) => {
    return <DatePicker calendarClassName={className} locale={locale} calendarStartDay={1} {...props} />;
};

const borderRadius = '40px';
const itemSize = 44;
const mutedColor = Color.ContentDisabled;

export const DatePickerContent = styled(StyledWrapper)`
    font-family: ${fontFamily};
    font-size: 14px;
    color: ${Color.ContentSecondary};
    display: inline-block;
    position: relative;
    z-index: 1; // For negative z-index inside

    .react-datepicker__header {
        text-align: center;
        padding-bottom: 4px;
        position: relative;

        &--time {
            padding-bottom: 8px;
            padding-left: 5px;
            padding-right: 5px;
        }
    }

    .react-datepicker__year-dropdown-container--select,
    .react-datepicker__month-dropdown-container--select,
    .react-datepicker__month-year-dropdown-container--select,
    .react-datepicker__year-dropdown-container--scroll,
    .react-datepicker__month-dropdown-container--scroll,
    .react-datepicker__month-year-dropdown-container--scroll {
        display: inline-block;
        margin: 0 15px;
    }

    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
        margin-top: 0;
        color: ${Color.ContentPrimary};
        font-weight: bold;
        font-size: 14px;
        line-height: 24px;
    }

    .react-datepicker-time__header {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .react-datepicker__navigation {
        align-items: center;
        background: none;
        display: flex;
        justify-content: center;
        text-align: center;
        cursor: pointer;
        position: absolute;
        top: 2px;
        padding: 0;
        border: none;
        z-index: 1;
        height: 24px;
        width: 24px;
        text-indent: -999em;
        overflow: hidden;

        &--previous {
            left: 2px;
        }

        &--next {
            right: 2px;

            &--with-time:not(&--with-today-button) {
                right: 85px;
            }
        }

        &--years {
            position: relative;
            top: 0;
            display: block;
            margin-left: auto;
            margin-right: auto;

            &-previous {
                top: 4px;
            }

            &-upcoming {
                top: -4px;
            }
        }
    }

    .react-datepicker__month-container {
        float: left;
    }

    .react-datepicker__year {
        text-align: center;

        &-wrapper {
            display: flex;
            flex-wrap: wrap;
            max-width: 180px;
        }

        .react-datepicker__year-text {
            display: inline-block;
            width: 84px;
            padding: 12px 0;
            margin: 2px 0;
        }
    }

    .react-datepicker__month {
        text-align: center;

        .react-datepicker__month-text,
        .react-datepicker__quarter-text {
            display: inline-block;
            width: 84px;
            padding: 12px 0;
            margin: 2px 0;
        }
    }

    .react-datepicker__time-container {
        float: right;
        width: 85px;

        &--with-today-button {
            display: inline;
            position: absolute;
            right: -87px;
            top: 0;
        }
    }

    .react-datepicker__week-number {
        color: ${mutedColor};
        display: inline-block;
        width: ${itemSize}px;
        line-height: ${itemSize}px;
        text-align: center;
        margin: 2px 0;

        &.react-datepicker__week-number--clickable {
            cursor: pointer;

            &:hover {
                border-radius: ${borderRadius};
                background-color: ${Color.InteractionGhostHover};
            }
        }
    }

    .react-datepicker__day-names,
    .react-datepicker__week {
        white-space: nowrap;
    }

    .react-datepicker__day-names {
        margin-bottom: -8px;
    }

    .react-datepicker__day-name {
        font-weight: bold;
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        color: ${Color.ContentSecondary};
        display: inline-block;
        width: ${itemSize}px;
        line-height: ${itemSize}px;
        text-align: center;
        margin: 2px 0;
    }

    .custom,
    .react-datepicker__day,
    .react-datepicker__month-text,
    .react-datepicker__quarter-text,
    .react-datepicker__year-text {
        cursor: pointer;

        &:hover {
            border-radius: ${borderRadius};
            background-color: ${Color.InteractionGhostHover};
        }

        &--today {
            position: relative;
            &::before {
                content: '';
                position: absolute;
                z-index: 3;
                left: 0;
                right: 0;
                bottom: 4px;
                margin: auto;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: ${Color.BackgroundBrand};
            }
        }

        &--outside-month {
            color: ${mutedColor};
        }

        &--highlighted {
            border-radius: ${borderRadius};
            box-shadow: inset 0 0 0 1px ${Color.BackgroundBrand};
        }

        &--range-start::after,
        &--selecting-range-start::after {
            border-bottom-left-radius: ${borderRadius};
            border-top-left-radius: ${borderRadius};
        }

        &--range-end::after,
        &--selecting-range-end::after {
            border-bottom-right-radius: ${borderRadius};
            border-top-right-radius: ${borderRadius};
        }

        &--in-range,
        &--in-selecting-range {
            position: relative;
            color: ${Color.InteractionDefaultNormal};
            &::after {
                content: '';
                position: absolute;
                z-index: -1;
                inset: 0;
                background: ${Color.InteractionDefaultSubtleNormal};
            }
        }

        &--selected,
        &--range-start,
        &--range-end,
        &--selecting-range-start,
        &--selecting-range-end {
            border-radius: ${borderRadius};
            background-color: ${Color.BackgroundBrand};
            color: ${Color.ContentInvertedPrimary};

            &:hover {
                background-color: ${Color.BackgroundBrand};
            }
        }

        &--disabled {
            cursor: default;
            color: ${mutedColor};

            &:hover {
                background-color: transparent;
            }
        }
    }

    .react-datepicker__children-container {
        width: 13.8rem;
        margin: 0.4rem;
        padding-right: 0.2rem;
        padding-left: 0.2rem;
        height: auto;
    }

    .react-datepicker__aria-live {
        position: absolute;
        clip-path: circle(0);
        border: 0;
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        width: 1px;
        white-space: nowrap;
    }
`;

export const DatePickerContentRange = DatePickerContent as any as ComponentClass<
    ReactDatePickerProps<any, true>
>;
