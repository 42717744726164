import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
    itemCacheKey,
    ItemDto,
    ItemReplacementBackToStockPayload,
    ItemReplacementWriteOffPayload,
    itemsService,
    UpdateReplacementResultFormData,
} from '@hofy/api-admin';
import { isWriteOff } from '@hofy/api-shared';
import { ReplacementResult } from '@hofy/api-shared';
import { isRequired, useForm, useToast, validateIf, validator } from '@hofy/ui';

export const useUpdateReplacementResult = (item: ItemDto, onEnd?: () => void) => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();
    const mutation = useMutation({
        mutationFn: (v: UpdateReplacementResultFormData) => {
            if (v.result === ReplacementResult.BackToStock) {
                let backToStockPayload: ItemReplacementBackToStockPayload = {
                    warehouseId: v.warehouseId!,
                    warehouseBinIdentifier: v.warehouseBinIdentifier,
                    hofySubsidiaryId: v.hofySubsidiaryId!,
                };
                return itemsService.updateReplacementResultBackToStock(item.id, backToStockPayload);
            }
            let writeOffPayload: ItemReplacementWriteOffPayload = {
                status: v.status!,
                hofySubsidiaryId: v.hofySubsidiaryId!,
            };
            return itemsService.updateReplacementResultWriteOff(item.id, writeOffPayload);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [itemCacheKey] });
            onEnd?.();
        },
    });

    const form = useForm<UpdateReplacementResultFormData>({
        initial: {
            result: ReplacementResult.BackToStock,
            status: null,
            warehouseId: null,
            warehouseBinIdentifier: null,
            hofySubsidiaryId: null,
        },
        onSubmit: mutation.mutate,
        onDiscard: onEnd,
        validate: validator<UpdateReplacementResultFormData>({
            result: isRequired('Replacement result is required'),
            hofySubsidiaryId: isRequired('Hofy subsidiary is required'),
            warehouseId: validateIf(
                p => p.result === ReplacementResult.BackToStock,
                v => {
                    if (v) {
                        return undefined;
                    }
                    return 'Warehouse is required for back to stock result';
                },
            ),
            status: validateIf(
                p => p.result === ReplacementResult.WriteOff,
                v => {
                    if (v && isWriteOff(v)) {
                        return undefined;
                    }
                    return 'Status is required for write-off result';
                },
            ),
        }),
        onInvalid(error) {
            if (error.result) {
                showToast({
                    type: 'negative',
                    message: error.result.toString(),
                });
            }
            if (error.status) {
                showToast({
                    type: 'negative',
                    message: error.status.toString(),
                });
            }
            if (error.warehouseId) {
                showToast({
                    type: 'negative',
                    message: error.warehouseId.toString(),
                });
            }
        },
    });

    return {
        form,
        isLoading: mutation.isPending,
        isError: mutation.isError,
    };
};
