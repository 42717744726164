import { ColorDye, getEnumValues } from '@hofy/global';

export enum TaxStatus {
    Pending = 'pending',
    Computed = 'computed',
    Prediction = 'prediction',
    Reported = 'reported',
    ReportError = 'report_error',
    Refunded = 'refunded',
    RefundError = 'refund_error',
    Error = 'error',
    Voided = 'voided',
    VoidError = 'void_error',
}

export const taxStatusColors: Record<TaxStatus, ColorDye> = {
    [TaxStatus.Pending]: 'orange',
    [TaxStatus.Prediction]: 'purple',
    [TaxStatus.Computed]: 'blue',
    [TaxStatus.Reported]: 'green',
    [TaxStatus.Refunded]: 'green',
    [TaxStatus.RefundError]: 'red',
    [TaxStatus.Error]: 'red',
    [TaxStatus.ReportError]: 'red',
    [TaxStatus.Voided]: 'grey',
    [TaxStatus.VoidError]: 'red',
};

export const validTaxStatuses = [TaxStatus.Computed, TaxStatus.Prediction, TaxStatus.Reported];

export const allTaxStatuses = getEnumValues<TaxStatus>(TaxStatus);
