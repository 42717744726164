export enum ClearanceError {
    ItemNotOrganizationOwned = 'item_not_organization_owned_error',
    ItemNotDevice = 'item_not_device_error',
    ItemNotActive = 'item_not_active_error',
    ItemArchived = 'item_archived_error',
    ItemBeingRepaired = 'item_being_repaired_error',
    ItemWrongLocation = 'item_wrong_location_error',
    ItemHasNoAddress = 'item_has_no_address_error',
    WrongTermsAndConditions = 'wrong_terms_and_conditions',
}
