import {
    type FloatingContext,
    type ReferenceType,
    useClick,
    useDismiss,
    useInteractions,
    useListNavigation,
    useRole,
} from '@floating-ui/react';
import { KeyboardEvent, useRef, useState } from 'react';

interface Options<T, RT extends ReferenceType> {
    context: FloatingContext<RT>;
    disabled?: boolean;
    onChange(value: T): void;
    onSearchEnter(index: number): void;
}

/**
 * Hook for handling list interactions for dropdowns with search input which is always focused:
 * - Keyboard navigation (up, down, home, end)
 * - Dismissing (escape, clicking outside)
 * - Clicking (mouse down)
 */
export const useA11ySearchListInteractions = <T, RT extends ReferenceType = ReferenceType>({
    context,
    disabled,
    onChange,
    onSearchEnter,
}: Options<T, RT>) => {
    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    const listRef = useRef<(HTMLElement | null)[]>([]);

    // Handles opening the floating element
    const { getReferenceProps, getFloatingProps } = useInteractions([
        useClick(context),
        useDismiss(context),
        useRole(context),
    ]);

    // Handles the list navigation where the reference is the search input
    const {
        getReferenceProps: getInputProps,
        getFloatingProps: getListFloatingProps,
        getItemProps,
    } = useInteractions([
        useListNavigation(context, {
            listRef,
            onNavigate: setActiveIndex,
            activeIndex,
            loop: true,
            focusItemOnOpen: false,
            virtual: true,
            allowEscape: true,
        }),
    ]);

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Enter' && activeIndex !== null) {
            event.preventDefault();
            onSearchEnter(activeIndex);
        }
    };

    const referenceProps = disabled ? () => undefined : getReferenceProps;
    const floatingProps = () => getFloatingProps(getListFloatingProps());
    const inputProps = () => getInputProps({ onKeyDown: handleKeyDown });
    const itemProps = (item: T) => getItemProps({ onClick: () => onChange(item) });

    return {
        referenceProps,
        floatingProps,
        inputProps,
        itemProps,
        activeIndex,
        listRef,
        setActiveIndex,
    };
};
