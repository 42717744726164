import React, { FC, ReactNode } from 'react';
import styled, { type CSSObject } from 'styled-components';

import { Color } from '@hofy/theme';
import { TestKeyAware } from '@hofy/ui';

import { CheckableContent } from './CheckableContent';

type CheckboxType = 'solid' | 'outlined';

type CheckboxState =
    | 'unchecked'
    | 'checked'
    | 'partial'
    | 'checked_disabled'
    | 'unchecked_disabled'
    | 'error';

type CheckboxPart = 'body' | 'mark';

interface CheckboxProps extends TestKeyAware {
    disabled?: boolean;
    isError?: boolean;
    isPartial?: boolean;
    type?: CheckboxType;
    checked: boolean;
    onChange?(v: boolean): void;
    children?: ReactNode;
}

/** @deprecated use `Checkbox` from `@hofy/ui` instead */
export const Checkbox: FC<CheckboxProps> = ({
    children,
    checked,
    disabled = false,
    isError = false,
    isPartial = false,
    type = 'solid',
    onChange,
    testKey,
}) => {
    return (
        <CheckboxContainer
            isError={isError}
            disabled={disabled}
            isPartial={isPartial}
            raw={!children}
            checkboxType={type}
        >
            <CheckableContent
                type='checkbox'
                checked={checked}
                onChange={onChange}
                disabled={disabled}
                testKey={testKey}
            >
                {children}
            </CheckableContent>
        </CheckboxContainer>
    );
};

const checkboxStyleConfig: Record<CheckboxType, Record<CheckboxState, Record<CheckboxPart, CSSObject>>> = {
    solid: {
        unchecked: {
            body: {
                width: 15,
                height: 15,
                borderRadius: 5,
                border: `1px solid ${Color.Neutral300}`,
                background: Color.BackgroundDefault,
                transform: 'translate(0, -50%)',
            },
            mark: {
                width: 3,
                height: 7,
                left: 6,
                transform: 'translate(0, -60%) rotate(45deg)',
                border: `1px solid ${Color.BackgroundDefault}`,
                borderTop: 'none',
                borderLeft: 'none',
            },
        },
        checked: {
            body: {
                border: 'none',
                background: Color.FoundationPurple,
            },
            mark: {},
        },
        unchecked_disabled: {
            body: {
                borderColor: Color.Neutral200,
            },
            mark: {},
        },
        checked_disabled: {
            body: {
                background: Color.Neutral200,
            },
            mark: {
                borderColor: Color.BackgroundDefault,
            },
        },
        error: {
            body: {
                border: `1px solid ${Color.FoundationNegative}`,
            },
            mark: {},
        },
        partial: {
            body: {},
            mark: {
                border: 'none',
                left: 5.4,
                width: 4.2,
                height: 1.5,
                background: Color.BackgroundDefault,
                transform: 'translate(0,-50%)',
            },
        },
    },
    outlined: {
        unchecked: {
            body: {
                width: 15,
                height: 15,
                borderRadius: 5,
                border: `1px solid ${Color.Neutral300}`,
                background: Color.BackgroundDefault,
                transform: 'translate(0, -50%)',
            },
            mark: {},
        },
        checked: {
            body: {
                border: `1px solid ${Color.FoundationPurple}`,
            },
            mark: {
                width: 3,
                height: 7,
                left: 6,
                transform: 'translate(0, -60%) rotate(45deg)',
                border: `1px solid ${Color.FoundationPurple}`,
                borderTop: 'none',
                borderLeft: 'none',
            },
        },
        unchecked_disabled: {
            body: {
                borderColor: Color.Neutral200,
            },
            mark: {},
        },
        checked_disabled: {
            body: {
                borderColor: Color.Neutral200,
            },
            mark: {
                borderColor: Color.Neutral200,
            },
        },
        error: {
            body: {
                border: `1px solid ${Color.FoundationNegative}`,
            },
            mark: {},
        },
        partial: {
            body: {},
            mark: {
                border: 'none',
                left: 5.4,
                width: 4.2,
                height: 1.5,
                background: Color.FoundationPurple,
                transform: 'translate(0,-50%)',
            },
        },
    },
};

interface CheckboxContainerProps {
    disabled: boolean;
    checkboxType: CheckboxType;
    isError: boolean;
    isPartial: boolean;
    raw: boolean;
}

const CheckboxContainer = styled.div<CheckboxContainerProps>(
    ({ disabled, checkboxType, isError, isPartial, raw }: CheckboxContainerProps) => {
        const baseStyle = checkboxStyleConfig[checkboxType];

        const styles: CSSObject = {
            '> input': { display: 'none' },
            '> label': {
                display: 'flex',
                flexDirection: 'row',
                position: 'relative',
                paddingLeft: raw ? 15 : 30,
                lineHeight: `24px`,
            },

            '> label::before': {
                display: 'block',
                position: 'absolute',
                top: '50%',
                left: 0,
                transformOrigin: '50% 50%',
                content: '""',
                ...baseStyle.unchecked.body,
                ...(disabled ? baseStyle.unchecked_disabled.body : {}),
                ...(isError ? baseStyle.error.body : {}),
            },
            '> label::after': {
                display: 'block',
                position: 'absolute',
                top: '50%',
                transformOrigin: '50% 50%',
                content: '""',
                ...baseStyle.unchecked.mark,
                ...(disabled ? baseStyle.unchecked_disabled.mark : {}),
                ...(isError ? baseStyle.error.mark : {}),
            },

            '> input:checked + label::before': {
                ...baseStyle.checked.body,
                ...(disabled ? baseStyle.checked_disabled.body : {}),
                ...(isPartial ? baseStyle.partial.body : {}),
            },
            '> input:checked + label::after': {
                ...baseStyle.checked.mark,
                ...(disabled ? baseStyle.checked_disabled.mark : {}),
                ...(isPartial ? baseStyle.partial.mark : {}),
            },
        };
        return styles;
    },
);
