import { motion } from 'framer-motion';
import React, { FC, useEffect, useState } from 'react';

import { DocumentType, IssueDetailsDto } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { FallbackCategoryImage } from '@hofy/product';
import {
    Box,
    FeaturedIcon,
    FormGridRow,
    FramedImage,
    Modals,
    opacityTransition,
    opacityVariants,
    Paragraph3,
    SectionTitle2,
    Skeleton,
    SvgIcon,
} from '@hofy/ui';

import { ImagePreviewModal } from '../../../components/design/imageEditor/ImagePreviewModal';
import { AttachmentCell, AttachmentFile } from '../../../components/domain/invoicing/AttachmentCell';
import { useDownloadImageDocument } from '../../../store/issues/useDownloadImageDocument';
import { useDownloadIssueDocument } from '../../../store/issues/useDownloadIssueDocument';
import { createUrlsFromBlobImage } from '../utils/createUrlsFromBlobImage';

interface IssueDocumentsSectionProps {
    issue: IssueDetailsDto;
}

export const IssueDocumentsSection: FC<IssueDocumentsSectionProps> = ({ issue }) => {
    const { download, isDownloading } = useDownloadIssueDocument();
    const { download: downloadImage } = useDownloadImageDocument();

    const { imageDocuments, otherDocuments } = issue.issueDocuments.reduce<{
        imageDocuments: AttachmentFile<UUID>[];
        otherDocuments: AttachmentFile<string>[];
    }>(
        (acc, doc) => {
            const key = doc.type === DocumentType.Image ? 'imageDocuments' : 'otherDocuments';
            acc[key].push({ id: doc.id, filename: doc.fileName });
            return acc;
        },
        { imageDocuments: [], otherDocuments: [] },
    );

    const [downloadedImages, setDownloadedImages] = useState<Record<string, Blob>>({});
    const [previewIndex, setPreviewIndex] = useState<number>(-1);
    const [isImageDownloading, setIsImageDownloading] = useState<boolean>(true);

    const imageIds = imageDocuments.map(img => img.id);
    const imageUrls = createUrlsFromBlobImage(downloadedImages, imageIds);

    const fetchAllImageBlobs = async () => {
        const downloadedBlobs = await Promise.all(
            imageDocuments.map(async imageDoc => {
                if (!issue.id || !imageDoc.id || !downloadImage) {
                    return { id: imageDoc.id, blob: undefined };
                }

                const imageBlob = await downloadImage(issue.id, imageDoc.id);
                return { id: imageDoc.id, blob: imageBlob };
            }),
        );

        const validBlobs = downloadedBlobs.filter(entry => entry.blob !== undefined);

        return Object.fromEntries(validBlobs.map(entry => [entry.id, entry.blob])) as Record<UUID, Blob>;
    };

    useEffect(() => {
        const downloadAllImages = async () => {
            if (imageDocuments.length === 0) {
                return;
            }

            const imageBlobs = await fetchAllImageBlobs();
            setDownloadedImages(imageBlobs);

            const allUrlsReady = imageDocuments.every(doc => imageBlobs[doc.id]);
            if (allUrlsReady) {
                setIsImageDownloading(false);
            }
        };

        downloadAllImages();
    }, [imageDocuments?.length, downloadImage, issue?.id]);

    const handleDownload = (fileId: UUID) => {
        download(issue.id, fileId);
    };

    const handleImageClick = (index: number) => {
        setPreviewIndex(index);
    };

    return (
        <>
            {issue.issueDocuments?.length ? (
                <>
                    {imageDocuments.length > 0 &&
                        (!isImageDownloading ? (
                            imageUrls.length > 0 && (
                                <FormGridRow columns={6}>
                                    <FormGridRow columns={6}>
                                        {imageUrls.map((image, index) => (
                                            <Box
                                                column
                                                as={motion.div}
                                                initial={false}
                                                variants={opacityVariants}
                                                transition={opacityTransition}
                                                key={`image-${index}`}
                                                onClick={() => handleImageClick(index)}
                                            >
                                                <FramedImage
                                                    src={image.url}
                                                    size={80}
                                                    fallback={<FallbackCategoryImage />}
                                                />
                                            </Box>
                                        ))}
                                    </FormGridRow>
                                </FormGridRow>
                            )
                        ) : (
                            <Skeleton height={80} fullWidth />
                        ))}

                    {otherDocuments.length > 0 && (
                        <FormGridRow columns={0}>
                            <Box column gap={8} className='mt-4'>
                                {otherDocuments.map((doc, index) => (
                                    <Box key={`doc-${index}`} row>
                                        <Box row flex={1} gap={8}>
                                            <FeaturedIcon icon={SvgIcon.File} shape='square' size={40} />
                                            <Box column>
                                                <SectionTitle2 flex={1}>{doc.filename}</SectionTitle2>
                                                <AttachmentCell
                                                    files={[doc]}
                                                    onDownload={handleDownload}
                                                    isDownloading={isDownloading}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                ))}
                            </Box>
                        </FormGridRow>
                    )}
                </>
            ) : (
                <Paragraph3>No files attached to this issue</Paragraph3>
            )}

            <Modals>
                {previewIndex > -1 && (
                    <ImagePreviewModal images={imageUrls} index={previewIndex} setIndex={setPreviewIndex} />
                )}
            </Modals>
        </>
    );
};
