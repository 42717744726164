import { useQuery } from '@tanstack/react-query';

import { assignmentService } from '@hofy/api-admin';
import { ServiceCostDto } from '@hofy/api-shared';
import { UUID } from '@hofy/global';

export const useConsumableCostQuery = (assignmentId: UUID, variantId: UUID, isBillable: boolean) => {
    const consumableCostQuery = useQuery<ServiceCostDto>({
        queryKey: ['consumableCost', assignmentId, variantId],
        queryFn: () => assignmentService.consumableCost(assignmentId, variantId),
        enabled: isBillable,
        staleTime: Infinity,
    });

    return {
        consumableCost: consumableCostQuery.data,
        isLoading: consumableCostQuery.isLoading,
        isError: consumableCostQuery.isError,
    };
};
