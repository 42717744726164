import { ColorDye, getEnumValues } from '@hofy/global';

export enum InvoiceStatus {
    Draft = 'draft',
    Open = 'open',
    Submitted = 'submitted',
    Overdue = 'overdue',
    ProcessingPayment = 'processing_payment',
    Paid = 'paid',
    Voided = 'voided',
}

export const invoiceStatusColors: Record<InvoiceStatus, ColorDye> = {
    [InvoiceStatus.Draft]: 'grey',
    [InvoiceStatus.Open]: 'orange',
    [InvoiceStatus.Submitted]: 'purple',
    [InvoiceStatus.Overdue]: 'red',
    [InvoiceStatus.ProcessingPayment]: 'cyan',
    [InvoiceStatus.Paid]: 'green',
    [InvoiceStatus.Voided]: 'grey',
};

export const allInvoiceStatuses = getEnumValues<InvoiceStatus>(InvoiceStatus);

export const invoicePaymentStatuses = [InvoiceStatus.ProcessingPayment, InvoiceStatus.Paid];
