import React, { FC, useEffect, useState } from 'react';

import { emptyItemsFilters, ItemDto, useItems } from '@hofy/api-admin';
import { Color } from '@hofy/theme';
import { AlertTooltipIcon, LabeledDebouncedInput, Spinner, SvgIcon, TooltipIcon } from '@hofy/ui';

export interface ItemScanInputProps {
    inputFor: 'CODE' | 'SERIAL';
    label: string;
    value: string | null;
    isRequired?: boolean;
    inputRef: React.RefObject<HTMLInputElement>;
    setValue(value: string | undefined): void;
}

export const ItemScanInput = ({
    inputFor,
    label,
    value,
    isRequired = false,
    inputRef,
    setValue,
}: ItemScanInputProps) => {
    const [inputValue, setInputValue] = useState(value || '');
    const [error, setError] = useState<string | undefined>(undefined);
    const itemFilter = {
        ...emptyItemsFilters,
    };
    if (inputFor === 'CODE') {
        itemFilter.code = inputValue;
    } else {
        itemFilter.serial = inputValue;
    }
    const { items, isLoading } = useItems(itemFilter, 1);

    useEffect(() => {
        if (!inputValue) {
            setError(undefined);
            setValue(inputValue);
            return;
        }
        if (items.length > 0) {
            setError('Already exists');
        } else {
            setError(undefined);
        }
        setValue(inputValue);
    }, [inputValue, items]);

    return (
        <LabeledDebouncedInput
            ref={inputRef}
            label={label}
            value={inputValue}
            onChange={setInputValue}
            rightSlot={
                isLoading ? <Spinner size={24} /> : <InputIcon items={items} inputValue={inputValue} />
            }
            errorMessage={error}
            isRequired={isRequired}
        />
    );
};

interface InputIconProps {
    items: ItemDto[];
    inputValue: string | null;
}
const InputIcon: FC<InputIconProps> = ({ items, inputValue }) => {
    if (inputValue && items.length) {
        return <AlertTooltipIcon />;
    }
    if (inputValue?.length) {
        return <TooltipIcon icon={SvgIcon.Check} iconColor={Color.ContentPositive} />;
    }
};
