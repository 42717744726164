import { WarehouseBinDto } from '@hofy/api-shared';
import { stringifyUrl, UUID } from '@hofy/global';
import { restClient } from '@hofy/rest';

import { CreateWarehouseBinPayload } from './types/CreateWarehouseBinPayload';
import { HofyWarehousePayload } from './types/HofyWarehousePayload';
import { ListHofyWarehousesFilter } from './types/ListHofyWarehousesFilter';
import { ClearanceWarehouseDetailsDto, HofyWarehouseDetailsDto } from './types/WarehouseDetailsDto';

class WarehouseService {
    public listHofyWarehouses = async (
        filter?: ListHofyWarehousesFilter,
    ): Promise<HofyWarehouseDetailsDto[]> => {
        return await restClient.getJson<HofyWarehouseDetailsDto[]>(
            stringifyUrl({
                url: '/api/admin/warehouses',
                query: { ...filter },
            }),
        );
    };

    public listClearanceWarehouses = async (): Promise<ClearanceWarehouseDetailsDto[]> => {
        return await restClient.getJson<ClearanceWarehouseDetailsDto[]>(
            stringifyUrl({
                url: '/api/admin/clearance-warehouses',
            }),
        );
    };

    public getHofyWarehouseById = async (id: UUID): Promise<HofyWarehouseDetailsDto> => {
        return await restClient.getJson<HofyWarehouseDetailsDto>(`/api/admin/warehouses/${id}`);
    };

    public createUpdateHofyWarehouse = (
        payload: HofyWarehousePayload,
        warehouseId?: UUID,
    ): Promise<{ id: UUID }> => {
        if (warehouseId) {
            return restClient.putJson(`/api/admin/warehouses/${warehouseId}`, payload);
        }
        return restClient.postJson('/api/admin/warehouses', payload);
    };

    public createWarehouseBin = async (
        warehouseId: UUID,
        payload: CreateWarehouseBinPayload,
    ): Promise<{ id: number }> => {
        return await restClient.postJson(`/api/admin/warehouses/${warehouseId}/bins`, payload);
    };

    public getWarehouseBinByIdentifier = (
        warehouseId: UUID,
        identifier: string,
    ): Promise<WarehouseBinDto> => {
        return restClient.getJson(
            `/api/admin/warehouses/${warehouseId}/bins/${encodeURIComponent(identifier)}`,
        );
    };
}

export const warehouseService = new WarehouseService();
