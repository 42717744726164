import React, { FC, useState } from 'react';

import { OrganizationDetailsDto } from '@hofy/api-admin';
import { HrisStatus } from '@hofy/api-shared';
import {
    Alert,
    AsyncButton,
    Box,
    Button,
    ConfirmModal,
    FormContainer,
    FormInput,
    FormSection,
    FormSwitch,
    Modal,
    ModalContent,
    ModalFooter,
    ModalHeader,
    Paragraph3,
} from '@hofy/ui';

import { CancelButton } from '../../../components/design/button/CancelButton';
import { useAdminI18n } from '../../../i18n/useAdminI18n';
import { useOrganizationHrisConfigQuery } from '../../../store/organizations/useOrganizationHrisConfigQuery';
import { useUnlinkOrganizationPartnership } from '../../../store/organizations/useUnlinkOrganizationDeelPartnership';
import { useUpdateOrganizationDeelData } from '../../../store/organizations/useUpdateOrganizationDeelData';

interface DeelUpdateModalProps {
    onClose(): void;
    organization: OrganizationDetailsDto;
}

export const DeelUpdateModal: FC<DeelUpdateModalProps> = ({ organization, onClose }) => {
    const { form, isLoading } = useUpdateOrganizationDeelData(organization, onClose);
    const { unlinkOrganizationPartnership, isLoading: unlinkLoading } = useUnlinkOrganizationPartnership(
        organization,
        onClose,
    );
    const { data: hrisConfig } = useOrganizationHrisConfigQuery(organization.id);
    const { tr } = useAdminI18n();
    const [showUnlinkConfirm, setShowUnlinkConfirm] = useState(false);

    const isUnbundlingEnabled = organization.unbundlingEnabled;
    const isLinkedToPartner = organization.partnerName !== null;
    const isDeelITDisabled = !isUnbundlingEnabled || isLinkedToPartner;

    const handleUnlink = () => {
        unlinkOrganizationPartnership();
    };

    return (
        <>
            <Modal onClose={onClose} width={450}>
                <ModalHeader title={tr('organization-page.deel-update.modal.title')} />
                <ModalContent>
                    <FormContainer marginBottom={24}>
                        {isDeelITDisabled ? (
                            <Alert
                                type='warning'
                                title={tr(
                                    'organization-page.deel-update.modal.form.deel-id-activate-alert-title',
                                )}
                                description={
                                    <Box column gap={8}>
                                        {!isUnbundlingEnabled && (
                                            <Paragraph3>
                                                {tr(
                                                    'organization-page.deel-update.modal.form.deel-id-activate-alert-unbundle-description',
                                                )}
                                            </Paragraph3>
                                        )}
                                        {isLinkedToPartner && (
                                            <Paragraph3>
                                                {tr(
                                                    'organization-page.deel-update.modal.form.deel-id-activate-alert-partner-description',
                                                )}
                                            </Paragraph3>
                                        )}
                                    </Box>
                                }
                            />
                        ) : (
                            <>
                                <FormInput
                                    api={form.fields.deelId}
                                    label={tr('organization-page.deel-update.modal.form.deel-id')}
                                />
                                {hrisConfig && hrisConfig.status === HrisStatus.Complete && (
                                    <Alert
                                        marginBottom={8}
                                        type='warning'
                                        title={tr('organization-page.deel-update.modal.hris-title')}
                                        description={tr(
                                            'organization-page.deel-update.modal.hris-description',
                                        )}
                                    />
                                )}
                                <FormSwitch
                                    api={form.fields.itReady}
                                    label={tr('organization-page.deel-update.modal.form.it-sync-data')}
                                />
                            </>
                        )}
                    </FormContainer>
                    {organization.partnerName && (
                        <FormSection
                            label={tr('organization-page.deel-update.modal.partner')}
                            marginBottom={24}
                        >
                            <Alert
                                type='negative'
                                title={tr('organization-page.deel-update.modal.unlink.alert.title')}
                                description={tr(
                                    'organization-page.deel-update.modal.unlink.alert.description',
                                )}
                            >
                                <Button
                                    label={tr('organization-page.deel-update.modal.unlink')}
                                    onClick={() => setShowUnlinkConfirm(true)}
                                    type='secondary'
                                    action='destructive'
                                />
                            </Alert>
                        </FormSection>
                    )}
                </ModalContent>
                <ModalFooter>
                    <CancelButton
                        label={tr('organization-page.deel-update.modal.cancel')}
                        onClick={onClose}
                    />
                    <AsyncButton
                        label={tr('organization-page.deel-update.modal.confirm')}
                        disableCheck
                        onClick={form.submit}
                        isLoading={isLoading}
                        disabled={!form.isValid}
                    />
                </ModalFooter>
            </Modal>
            {showUnlinkConfirm && (
                <ConfirmModal
                    keyPrefix='organization-page.deel-update.modal.unlink'
                    onClose={() => setShowUnlinkConfirm(false)}
                    onConfirm={handleUnlink}
                    width={400}
                    isLoading={unlinkLoading}
                    action='destructive'
                />
            )}
        </>
    );
};
