import React, { FC } from 'react';

import { Country } from '@hofy/global';
import { FeaturedIcon, SvgIcon } from '@hofy/ui';

import { LocationCard } from './LocationCard';

interface ClearanceWarehouseLocationCardProps {
    label?: string;
    name: string;
    country?: Country;
}

export const ClearanceWarehouseLocationCard: FC<ClearanceWarehouseLocationCardProps> = ({
    label,
    name,
    country,
}) => {
    return (
        <LocationCard
            icon={<FeaturedIcon icon={SvgIcon.DollarSign} shape='circle' size={32} variant='vivid' />}
            label={label}
            name={name}
            country={country}
        />
    );
};
