import React, { FC, ReactNode } from 'react';
import { Route, type RouteChildrenProps, Switch, useHistory } from 'react-router-dom';

import { JobContext } from '@hofy/api-admin';
import { pathUuid, UUID } from '@hofy/global';
import { getEnumParam, getStringParam } from '@hofy/router';

import { AdminNavLink } from '../../../components/routing/AdminNavLink';
import { InvoiceDetailTabs } from '../../../store/invoices/types/InvoiceDetailTabs';
import { AdminInvoicingTab } from '../../../store/invoicing/types/AdminInvoicingTab';
import { InvoicingTabRouterProps } from '../types/InvoicingTabRouterProps';
import { InvoiceDetailsOverlay } from './invoiceDetailsPage/InvoiceDetailsOverlay';
import { InvoicesPage } from './InvoicesPage';

export const InvoicesRouter: FC<InvoicingTabRouterProps> = ({ tab, tabs, onChangeTab }) => {
    const history = useHistory();
    const invoicesNavLink = `${AdminNavLink.Invoicing}/${AdminInvoicingTab.Invoices}`;

    const handleOpenInvoice = (id: UUID, tab: InvoiceDetailTabs, method: 'push' | 'replace') => {
        history[method](`${invoicesNavLink}/${id}/${tab}`);
    };

    const handleOpenJobs = () => {
        history.push(`${AdminNavLink.Jobs}?context=${JobContext.Invoices}`);
    };

    return (
        <Route path={invoicesNavLink}>
            <Switch>
                <Route path={invoicesNavLink} exact>
                    <InvoicesPage
                        tab={tab}
                        onChangeTab={onChangeTab}
                        tabs={tabs}
                        onOpenInvoice={invoiceId =>
                            handleOpenInvoice(invoiceId, InvoiceDetailTabs.Details, 'push')
                        }
                        onOpenJobs={handleOpenJobs}
                    />
                </Route>
                <InvoiceDetailsRoute path={`${invoicesNavLink}/:invoiceId(${pathUuid})/:tab`}>
                    {({ tab, invoiceId }) => (
                        <InvoiceDetailsOverlay
                            invoiceId={invoiceId}
                            tab={tab}
                            onChangeTab={tab => handleOpenInvoice(invoiceId, tab, 'replace')}
                            onOpenInvoice={invoiceId =>
                                handleOpenInvoice(invoiceId, InvoiceDetailTabs.Details, 'push')
                            }
                        />
                    )}
                </InvoiceDetailsRoute>
            </Switch>
        </Route>
    );
};

interface InvoiceDetailsRouteProps {
    path: string;

    children(v: { tab: InvoiceDetailTabs; invoiceId: UUID }): ReactNode;
}

const InvoiceDetailsRoute: FC<InvoiceDetailsRouteProps> = ({ children, ...props }) => {
    const renderChildren = (p: RouteChildrenProps) => {
        const invoiceId = getStringParam(p.match?.params || {}, 'invoiceId') as UUID;
        const tab = getEnumParam<InvoiceDetailTabs>(p.match?.params || {}, 'tab', InvoiceDetailTabs);

        if (invoiceId && tab) {
            return children({
                tab,
                invoiceId,
            });
        }

        return null;
    };
    return <Route {...props}>{renderChildren}</Route>;
};
