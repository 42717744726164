import React, { FC, useMemo } from 'react';

import { PostAddressView } from '@hofy/address';
import {
    PurchaseOrderType,
    useHofyWarehouseQuery,
    useShipmentQuery,
    useSupplierDetailsQuery,
    useVariantsWithProductAndPrice,
} from '@hofy/api-admin';
import { AddressType, emptyAddressPayload, isShipmentFromSupplierToUser } from '@hofy/api-shared';
import { CountryLabel } from '@hofy/core';
import { Currency, isPositivePercent, percentToNumber, Price, priceToNumber, zeroPrice } from '@hofy/global';
import { formatDate } from '@hofy/helpers';
import { useDecimal, usePrice } from '@hofy/hooks';
import {
    BaseTable,
    Box,
    FormContainer,
    FormSection,
    Labeled,
    LabeledText,
    Paragraph3,
    Skeleton,
    UseForm,
    useFormArrayField,
} from '@hofy/ui';

import { PurchaseOrderTypeChip } from '../../../../../components/domain/purchaseOrders/PurchaseOrderTypeChip';
import { PurchaseOrderHeaderFormData } from '../../../../../store/purchaseOrders/usePurchaseOrderHeaderForm';
import {
    emptyPurchaseOrderItemData,
    PurchaseOrderFormItemData,
    PurchaseOrderItemsFormData,
} from '../../../../../store/purchaseOrders/usePurchaseOrderItemsForm';
import { getTrackingLink } from '../../../../shipmentSlideout/components/CourierDetails';
import { calculateTotals } from './PurchaseOrderItemsForm';
import { PurchaseOrderSummaryLine } from './PurchaseOrderSummaryLine';
import { VariantDisplay } from './VariantDisplay';

interface PurchaseOrderReviewFormProps {
    headerForm: UseForm<PurchaseOrderHeaderFormData>;
    itemsForm: UseForm<PurchaseOrderItemsFormData>;
}

export const PurchaseOrderReviewForm: FC<PurchaseOrderReviewFormProps> = ({ headerForm, itemsForm }) => {
    const { formatPrice } = usePrice();
    const { formatPercent } = useDecimal();

    const { data: supplier, isLoading: isLoadingSupplier } = useSupplierDetailsQuery(
        headerForm.values.supplierId,
    );
    const { data: warehouse, isLoading: isLoadingWarehouse } = useHofyWarehouseQuery(
        headerForm.values.toWarehouseId,
    );
    const { data: shipment, isLoading: isLoadingShipment } = useShipmentQuery(headerForm.values.shipmentId);
    const { isLoading: isLoadingVariants, variantsById } = useVariantsWithProductAndPrice(
        {
            country: supplier?.billingAddress.country!,
            currency: headerForm.values.currency,
            purchaseOrderType: headerForm.values.isDropship
                ? PurchaseOrderType.Dropship
                : PurchaseOrderType.Warehouse,
        },
        supplier !== undefined,
    );

    const items = useFormArrayField(
        itemsForm.fields.items,
        emptyPurchaseOrderItemData(headerForm.values.currency),
    );
    const { totalOrderNetValue, totalTaxValue, totalOrderValue } = useMemo(
        () => calculateTotals(items, itemsForm, headerForm.values.currency),
        [items],
    );

    const deliveryAddress = headerForm.values.isDropship ? shipment?.toAddress : warehouse?.address;

    if (isLoadingSupplier || isLoadingWarehouse || isLoadingShipment || isLoadingVariants) {
        return <Skeleton height={400} />;
    }

    return (
        <FormContainer marginTop={10} marginBottom={10}>
            <FormSection label='Order details'>
                <Box column>
                    <Box row alignContent='flex-start' paddingBottom={12}>
                        <LabeledText flex={1} label='Supplier' content={supplier?.name} />
                        <LabeledText flex={1} label='Currency' content={headerForm.values.currency} />
                        <Box flex={1}>
                            <Labeled
                                label='Purchase order type'
                                content={<PurchaseOrderTypeChip isDropship={headerForm.values.isDropship} />}
                            />
                        </Box>
                    </Box>
                    <Box row alignContent='flex-start' paddingBottom={12}>
                        <LabeledText
                            flex={1}
                            label='Order date'
                            content={formatDate(headerForm.values.orderedOn)}
                        />
                        <LabeledText
                            flex={1}
                            label='Delivery date'
                            content={formatDate(headerForm.values.estimatedReceiveOn)}
                        />
                        <LabeledText flex={1} label='Supplier' content={supplier?.name || '--'} />
                    </Box>
                </Box>
            </FormSection>
            <FormSection marginTop={10} label='Shipping'>
                <Box row alignContent='flex-start'>
                    <LabeledText flex={1} label='Warehouse' content={warehouse?.name} />
                    <Labeled
                        flex={1}
                        content={<CountryLabel country={supplier?.shippingAddress?.country} />}
                        label='From country'
                    />
                    <Labeled
                        flex={1}
                        content={<CountryLabel country={deliveryAddress?.country} />}
                        label='To country'
                    />
                    <LabeledText
                        flex={1}
                        label='Tracking Reference'
                        content={headerForm.values.courierReference}
                    />
                </Box>
                <Box row alignContent='flex-start'>
                    <LabeledText
                        flex={1}
                        label='User'
                        content={
                            shipment && isShipmentFromSupplierToUser(shipment)
                                ? `${shipment.toUser.firstName} ${shipment.toUser.lastName}`
                                : '--'
                        }
                    />
                    <LabeledText
                        flex={1}
                        label='From address'
                        content={
                            <PostAddressView
                                address={
                                    supplier?.shippingAddress || emptyAddressPayload(AddressType.Billing)
                                }
                            />
                        }
                    />
                    <LabeledText
                        flex={1}
                        label='To address'
                        content={
                            <PostAddressView
                                address={deliveryAddress || emptyAddressPayload(AddressType.Billing)}
                            />
                        }
                    />
                    <Labeled
                        flex={1}
                        label='Tracking link'
                        content={getTrackingLink(headerForm.values.trackingLink)}
                    />
                </Box>
            </FormSection>
            <BaseTable
                data={itemsForm.values.items}
                toKey={(_, index) => index}
                rowHorizontalPadding={0}
                headerHorizontalPadding={0}
                emptyContent={<Paragraph3>No item</Paragraph3>}
                fullSize
                columns={[
                    {
                        id: 'variant',
                        header: 'SKU',
                        flexGrow: 1,
                        renderer: v => (
                            <VariantDisplay
                                flex={1}
                                name={v.name || '--'}
                                variant={variantsById[v.variantId!]}
                            />
                        ),
                    },
                    {
                        id: 'supplierCode',
                        header: 'SupplierCode',
                        width: 100,
                        flexGrow: 0,
                        renderer: v => v.supplierCode,
                    },
                    {
                        id: 'estimatedReceiveAt',
                        header: 'Delivery',
                        width: 100,
                        flexGrow: 0,
                        renderer: v => formatDate(v.estimatedReceiveOn),
                    },
                    {
                        id: 'warranty',
                        header: 'Warranty',
                        width: 100,
                        flexGrow: 0,
                        renderer: v => v.warranty,
                    },
                    {
                        id: 'quantity',
                        header: 'Quantity',
                        width: 100,
                        flexGrow: 0,
                        renderer: v => v.quantity,
                    },
                    {
                        id: 'unitNetPrice',
                        header: 'Unit net price',
                        width: 100,
                        flexGrow: 0,
                        renderer: v => formatPrice(v.unitNetPrice),
                    },
                    {
                        id: 'tax',
                        header: 'Tax',
                        width: 100,
                        flexGrow: 0,
                        renderer: v => formatPercent(v.taxRate),
                    },
                    {
                        id: 'totalTaxValue',
                        header: 'Total tax value',
                        width: 100,
                        flexGrow: 0,
                        renderer: v => formatPrice(calculateItemTotalTax(v, headerForm.values.currency)),
                    },
                    {
                        id: 'totalGrossPrice',
                        header: 'Total gross price',
                        width: 150,
                        flexGrow: 0,
                        renderer: v => formatPrice(calculateItemTotal(v, headerForm.values.currency)),
                    },
                    {
                        id: 'notes',
                        header: 'Notes',
                        width: 100,
                        flexGrow: 0,
                        renderer: v => v.notes,
                    },
                ]}
            />
            <PurchaseOrderSummaryLine label='Total net value:' value={totalOrderNetValue} />
            <PurchaseOrderSummaryLine label='Total tax value:' value={totalTaxValue} />
            <PurchaseOrderSummaryLine
                label='Shipping/handling cost:'
                value={itemsForm.values.totalHandlingCost}
            />
            <PurchaseOrderSummaryLine
                label='Total order value:'
                subLabel='Incl. tax and shipping cost'
                value={totalOrderValue}
            />
        </FormContainer>
    );
};

const calculateItemTotal = (item: PurchaseOrderFormItemData, currency: Currency): Price => {
    const grossPrice =
        priceToNumber(item.unitNetPrice) * item.quantity +
        priceToNumber(calculateItemTotalTax(item, currency));

    return {
        amount: grossPrice.toString(),
        currency: currency,
    };
};

const calculateItemTotalTax = (item: PurchaseOrderFormItemData, currency: Currency): Price => {
    if (!isPositivePercent(item.taxRate)) {
        return zeroPrice(currency);
    }

    const totalTaxValue =
        item.quantity * priceToNumber(item.unitNetPrice) * (percentToNumber(item.taxRate) / 100);

    return {
        amount: totalTaxValue.toString(),
        currency: currency,
    };
};
