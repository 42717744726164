export const itemLocationI18n = {
    'item-location.with-user': 'With user',
    'item-location.at-warehouse': 'At warehouse',
    'item-location.hofy-warehouse': 'Hofy warehouse',
    'item-location.organization-warehouse': 'Organization warehouse',
    'item-location.clearance-warehouse': 'Clearance warehouse',
    'item-location.supplier': 'Supplier',
    'item-location.with-courier': 'With courier',
    'item-location.write-off': 'Write off',
};
