import { useMutation, useQueryClient } from '@tanstack/react-query';

import { invoiceCacheKey, invoicesService } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { useToast } from '@hofy/ui';

export const useSyncInvoiceToDeel = (invoiceId: UUID) => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();

    const mutation = useMutation({
        mutationFn: () => invoicesService.syncToDeel(invoiceId),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [invoiceCacheKey] });
            showToast({
                type: 'positive',
                message: 'Sync to Deel scheduled.',
            });
        },
    });

    return {
        syncInvoiceToDeel: mutation.mutate,
        isSyncInvoiceToDeelLoading: mutation.isPending,
    };
};
