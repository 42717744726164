export const clearanceErrorI18n = {
    'api-error.item-not-organization-owned.title': 'Item not organization owned error',
    'api-error.item-not-organization-owned.message': 'Cannot perform this operation',
    'api-error.item-not-device.title': 'Item not device error',
    'api-error.item-not-device.message': 'Cannot perform this operation',
    'api-error.item-not-active.title': 'Item not active error',
    'api-error.item-not-active.message': 'Cannot perform this operation',
    'api-error.item-archived.title': 'Item archived error',
    'api-error.item-archived.message': 'Cannot perform this operation',
    'api-error.item-being-repaired.title': 'Item being repaired error',
    'api-error.item-being-repaired.message': 'Cannot perform this operation',
    'api-error.item-wrong-location.title': 'Item wrong location error',
    'api-error.item-wrong-location.message': 'Cannot perform this operation',
    'api-error.item-has-no-address.title': 'Item has no address error',
    'api-error.item-has-no-address.message': 'Cannot perform this operation',
    'api-error.wrong-terms-and-conditions.title': 'Wrong terms and conditions',
    'api-error.wrong-terms-and-conditions.message': 'Cannot perform this operation',
};
