import { isEqual } from 'lodash-es';
import { DateTime, type DateTimeUnit } from 'luxon';

import { DateRangeName, DateRangeStrings, FromToDateStrings, getEnumValues } from '@hofy/global';

import { now, toISODate } from './DateTime';

const allDateRangeNames = getEnumValues<DateRangeName>(DateRangeName);

const isDateRangeName = (name: DateRangeName | DateRangeStrings): name is DateRangeName => {
    return allDateRangeNames.includes(name as DateRangeName);
};

const toRangeString = (start: DateTime, end: DateTime): FromToDateStrings => {
    return {
        from: toISODate(start),
        to: toISODate(end),
    };
};

const toPeriodRangeString = (period: DateTimeUnit, date = now()): FromToDateStrings => {
    return toRangeString(date.startOf(period), date.endOf(period));
};

export const dateRangeNameToRangeMap: Record<DateRangeName, () => FromToDateStrings> = {
    [DateRangeName.Today]: () => toPeriodRangeString('day'),
    [DateRangeName.ThisWeek]: () => toPeriodRangeString('week'),
    [DateRangeName.ThisMonth]: () => toPeriodRangeString('month'),
    [DateRangeName.ThisQuarter]: () => toPeriodRangeString('quarter'),
    [DateRangeName.ThisYear]: () => toPeriodRangeString('year'),

    [DateRangeName.NextWeek]: () => toPeriodRangeString('week', now().plus({ weeks: 1 })),
    [DateRangeName.NextMonth]: () => toPeriodRangeString('month', now().plus({ months: 1 })),
    [DateRangeName.Next3Months]: () => {
        const start = now().plus({ months: 1 }).startOf('month');
        const end = start.plus({ months: 2 }).endOf('month');
        return toRangeString(start, end);
    },
    [DateRangeName.NextQuarter]: () => toPeriodRangeString('quarter', now().plus({ quarters: 1 })),
    [DateRangeName.NextYear]: () => toPeriodRangeString('year', now().plus({ years: 1 })),
};

export const dateRangeNameToRange = (name: DateRangeName | DateRangeStrings): DateRangeStrings => {
    if (isDateRangeName(name)) {
        return dateRangeNameToRangeMap[name]();
    }

    return name;
};

export const dateRangeToRangeName = (range: DateRangeStrings): DateRangeName | null => {
    for (const name of allDateRangeNames) {
        const current = dateRangeNameToRangeMap[name]();
        if (isEqual(current, range)) {
            return name;
        }
    }
    return null;
};

export const dateRangeSets = {
    future: [
        DateRangeName.Today,
        DateRangeName.NextWeek,
        DateRangeName.NextMonth,
        DateRangeName.NextQuarter,
        DateRangeName.NextYear,
    ],
    current: [
        DateRangeName.Today,
        DateRangeName.ThisWeek,
        DateRangeName.ThisMonth,
        DateRangeName.ThisQuarter,
        DateRangeName.ThisYear,
    ],
};
