import React, { FC } from 'react';

import { PurchaseOrderDto } from '@hofy/api-admin';

import { ShipmentTrackingLink } from '../../../shipmentsPage/components/table/ShipmentTrackingLink';

interface POTrackingLinkProps {
    purchaseOrder: PurchaseOrderDto;
}

export const POTrackingLink: FC<POTrackingLinkProps> = ({ purchaseOrder }) => {
    return (
        <ShipmentTrackingLink
            link={purchaseOrder.trackingLink}
            trackingId={purchaseOrder.courierReference ?? 'link'}
        />
    );
};
