import React, { FC } from 'react';

import { BulkStorageOrderItemDto } from '@hofy/api-admin';
import { useTrProductCategory } from '@hofy/api-shared';
import { ProductImage } from '@hofy/product';
import { Color } from '@hofy/theme';
import { Box, Paragraph3, Paragraph4 } from '@hofy/ui';

interface BulkStorageOrderProductInfoProps {
    item: BulkStorageOrderItemDto;
}

export const BulkStorageOrderProductInfo: FC<BulkStorageOrderProductInfoProps> = ({ item }) => {
    const trCategory = useTrProductCategory();
    return (
        <>
            <ProductImage size={58} image={item.variant.image} category={item.product.category} />
            <Box flex={1}>
                <Paragraph3 bold color={Color.ContentPrimary}>
                    {item.product.name}
                </Paragraph3>
                <Paragraph4>{item.variant.sku ?? '--'}</Paragraph4>
                <Paragraph3>{trCategory(item.product.category)}</Paragraph3>
            </Box>
        </>
    );
};
