import { ColorDye, getEnumValues } from '@hofy/global';

export enum InvoicingEntityFilter {
    Hofy = 'hofy',
    Two = 'two',
    Deel = 'deel',
}

export const invoicingEntityFilterColors: Record<InvoicingEntityFilter, ColorDye> = {
    [InvoicingEntityFilter.Hofy]: 'purple',
    [InvoicingEntityFilter.Two]: 'teal',
    [InvoicingEntityFilter.Deel]: 'blue',
};

export const allInvoicingEntityFilters = getEnumValues<InvoicingEntityFilter>(InvoicingEntityFilter);

export const invoicingEntityFilterToIsTwo = (filter: InvoicingEntityFilter | null): boolean | null => {
    if (filter === null) {
        return null;
    }

    return filter === InvoicingEntityFilter.Two;
};

export const invoicingEntityFilterToIsDeel = (filter: InvoicingEntityFilter | null): boolean | null => {
    if (filter === null) {
        return null;
    }

    return filter === InvoicingEntityFilter.Deel;
};
