export enum AddonError {
    PublicAddonCannotBeInactive = 'public_addon_cannot_be_inactive',
    PrivateAddonCannotHaveBlankDuration = 'private_addon_cannot_have_blank_duration',
    PrivateAddonCannotHaveBlankPaymentSchema = 'private_addon_cannot_have_blank_payment_schema',
    InvalidAddonBillingFrequency = 'addon_has_invalid_billing_frequency',
    InvalidAddonsForAcquisitionType = 'invalid_addons_for_acquisition_type',
    MissingAddonID = 'addon_id_missing',
    AddonNotFound = 'addon_not_found',
    InvalidAddonLength = 'invalid_addon_length',
    AddonDoesNotMatchContractSettings = 'addon_does_not_match_contract_setting',
}
