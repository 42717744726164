import { ColorDye, getEnumValues } from '@hofy/global';

export enum InvoiceEntryType {
    Saas = 'saas',
    Rental = 'rental',
    LaptopConfig = 'laptop_config',
    CertifiedDataErasure = 'certified_data_erasure',
    Mdm = 'mdm',
    AppResale = 'app_resale',
    AssembledDelivery = 'assembled_delivery',
    EndOfContractPurchase = 'end_of_contract_purchase',
    Purchase = 'purchase',
    Cancellation = 'cancellation',
    WelcomePack = 'welcome_pack',
    ItServiceDesk = 'it_service_desk',
    CreditNote = 'credit_note',
    Other = 'other',
    CustomSourcing = 'custom_sourcing',
    CustomShipping = 'custom_shipping',
    Repair = 'repair',
    RepairWithParts = 'repair_with_parts',
    Replacement = 'replacement',
    AccessoryReplacement = 'accessory_replacement',
    Loaner = 'loaner',
    ExpressShipping = 'express_shipping',
    RedistributionFee = 'redistribution_fee',
    Packaging = 'packaging',
    SingleItemFee = 'single_item_fee',
    NonHofyDeviceCharge = 'non_hofy_device_charge',
    StoreAndReuseFee = 'store_and_reuse_fee',
    ServiceBundle = 'service_bundle',
    ExtendedWarranty = 'extended_warranty',
    Shipping = 'shipping',
    Clearance = 'clearance',
    StoreAndReuseCustomerOwnedStorageFee = 'store_and_reuse_customer_owned_storage_fee',
    StoreAndReuseCustomerOwnedFee = 'store_and_reuse_customer_owned_fee',
    BYODActivationFee = 'byod_activation_fee',
    BYODStorageFee = 'byod_storage_fee',
    DeviceUnlockFee = 'device_unlock_fee',
}

export const invoiceEntryTypeColor: Record<InvoiceEntryType, ColorDye> = {
    [InvoiceEntryType.Saas]: 'blue',
    [InvoiceEntryType.Rental]: 'green',
    [InvoiceEntryType.LaptopConfig]: 'orange',
    [InvoiceEntryType.CertifiedDataErasure]: 'orange',
    [InvoiceEntryType.Mdm]: 'blue',
    [InvoiceEntryType.AppResale]: 'magenta',
    [InvoiceEntryType.ItServiceDesk]: 'blue',
    [InvoiceEntryType.AssembledDelivery]: 'magenta',
    [InvoiceEntryType.EndOfContractPurchase]: 'blue',
    [InvoiceEntryType.Purchase]: 'blue',
    [InvoiceEntryType.Cancellation]: 'red',
    [InvoiceEntryType.WelcomePack]: 'green',
    [InvoiceEntryType.CreditNote]: 'raspberry',
    [InvoiceEntryType.CustomShipping]: 'magenta',
    [InvoiceEntryType.CustomSourcing]: 'magenta',
    [InvoiceEntryType.Repair]: 'magenta',
    [InvoiceEntryType.RepairWithParts]: 'magenta',
    [InvoiceEntryType.Replacement]: 'magenta',
    [InvoiceEntryType.AccessoryReplacement]: 'blue',
    [InvoiceEntryType.Loaner]: 'magenta',
    [InvoiceEntryType.ExpressShipping]: 'magenta',
    [InvoiceEntryType.Shipping]: 'magenta',
    [InvoiceEntryType.RedistributionFee]: 'magenta',
    [InvoiceEntryType.Packaging]: 'magenta',
    [InvoiceEntryType.ExtendedWarranty]: 'magenta',
    [InvoiceEntryType.ServiceBundle]: 'magenta',
    [InvoiceEntryType.SingleItemFee]: 'magenta',
    [InvoiceEntryType.NonHofyDeviceCharge]: 'magenta',
    [InvoiceEntryType.Other]: 'magenta',
    [InvoiceEntryType.StoreAndReuseFee]: 'blue',
    [InvoiceEntryType.Clearance]: 'blue',
    [InvoiceEntryType.StoreAndReuseCustomerOwnedStorageFee]: 'blue',
    [InvoiceEntryType.StoreAndReuseCustomerOwnedFee]: 'blue',
    [InvoiceEntryType.BYODActivationFee]: 'blue',
    [InvoiceEntryType.BYODStorageFee]: 'blue',
    [InvoiceEntryType.DeviceUnlockFee]: 'blue',
};

export const allInvoiceEntryTypes = getEnumValues<InvoiceEntryType>(InvoiceEntryType).sort();

export const nonRentalEntryTypes = [
    InvoiceEntryType.Saas,
    InvoiceEntryType.Mdm,
    InvoiceEntryType.AppResale,
    InvoiceEntryType.ItServiceDesk,
    InvoiceEntryType.Other,
    InvoiceEntryType.NonHofyDeviceCharge,
    InvoiceEntryType.Clearance,
].sort();

export const rentalEntryTypes = [
    InvoiceEntryType.Rental,
    InvoiceEntryType.LaptopConfig,
    InvoiceEntryType.AssembledDelivery,
    InvoiceEntryType.EndOfContractPurchase,
    InvoiceEntryType.Cancellation,
    InvoiceEntryType.WelcomePack,
    InvoiceEntryType.Other,
    InvoiceEntryType.CustomShipping,
    InvoiceEntryType.CustomSourcing,
    InvoiceEntryType.Repair,
    InvoiceEntryType.RepairWithParts,
    InvoiceEntryType.Replacement,
    InvoiceEntryType.ExpressShipping,
    InvoiceEntryType.RedistributionFee,
    InvoiceEntryType.Packaging,
    InvoiceEntryType.SingleItemFee,
    InvoiceEntryType.ExtendedWarranty,
    InvoiceEntryType.ServiceBundle,
].sort();

export const periodRequiredTypes = [
    InvoiceEntryType.Rental,
    InvoiceEntryType.ServiceBundle,
    InvoiceEntryType.ExtendedWarranty,
    InvoiceEntryType.Saas,
    InvoiceEntryType.Mdm,
    InvoiceEntryType.AppResale,
    InvoiceEntryType.ItServiceDesk,
];

export const productCategoryRequiredTypes = [
    InvoiceEntryType.AccessoryReplacement,
    InvoiceEntryType.Purchase,
    InvoiceEntryType.Clearance,
];

export const nonOrganizationEntryTypes = [InvoiceEntryType.Purchase, InvoiceEntryType.Clearance];
