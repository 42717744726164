import { ColorDye, DateTimeString } from '@hofy/global';

export enum DeelSyncStatus {
    Pending = 'pending',
    Submitted = 'submitted',
    Error = 'error',
    NetworkError = 'network_error',
}

export const deelStatusColors: Record<DeelSyncStatus, ColorDye> = {
    [DeelSyncStatus.Pending]: 'orange',
    [DeelSyncStatus.Submitted]: 'green',
    [DeelSyncStatus.Error]: 'red',
    [DeelSyncStatus.NetworkError]: 'red',
};

export const allDeelSyncStatuses = Object.values(DeelSyncStatus);

export interface DeelStatusDto {
    id: string | null;
    status: DeelSyncStatus | null;
    successAt: DateTimeString | null;
    errorAt: DateTimeString | null;
    errorReason: string | null;
}
