import { AddressDto } from './AddressDto';
import { ItemLocation } from './ItemLocation';

interface AddressAware {
    address: AddressDto | null;
}

export interface ShipmentLocationUser<U> extends AddressAware {
    type: ItemLocation.WithUser;
    user: U;
}

interface ShipmentLocationHofyWarehouse<W> extends AddressAware {
    type: ItemLocation.HofyWarehouse;
    warehouse: W;
}

interface ShipmentLocationOrgWarehouse<W> extends AddressAware {
    type: ItemLocation.OrganizationWarehouse;
    warehouse: W;
}

interface ShipmentLocationClearanceWarehouse<W> extends AddressAware {
    type: ItemLocation.ClearanceWarehouse;
    warehouse: W;
}

interface ShipmentLocationSupplier<S> extends AddressAware {
    type: ItemLocation.Supplier;
    // TODO HOF-9116 - Remove null once supplier are specified when creating dropships
    supplier: S | null;
}

export type ShipmentLocation<U, W, S> =
    | ShipmentLocationUser<U>
    | ShipmentLocationHofyWarehouse<W>
    | ShipmentLocationOrgWarehouse<W>
    | ShipmentLocationClearanceWarehouse<W>
    | ShipmentLocationSupplier<S>;

export const isShipmentLocationUser = <U, W, S>(
    l: ShipmentLocation<U, W, S>,
): l is ShipmentLocationUser<U> => {
    return l.type === ItemLocation.WithUser;
};

export const isShipmentLocationHofyWarehouse = <U, W, S>(
    l: ShipmentLocation<U, W, S>,
): l is ShipmentLocationHofyWarehouse<W> => {
    return l.type === ItemLocation.HofyWarehouse;
};

export const isShipmentLocationClearanceWarehouse = <U, W, S>(
    l: ShipmentLocation<U, W, S>,
): l is ShipmentLocationClearanceWarehouse<W> => {
    return l.type === ItemLocation.ClearanceWarehouse;
};

export const isShipmentLocationOrgWarehouse = <U, W, S>(
    l: ShipmentLocation<U, W, S>,
): l is ShipmentLocationOrgWarehouse<W> => {
    return l.type === ItemLocation.OrganizationWarehouse;
};

export const isShipmentLocationSupplier = <U, W, S>(
    l: ShipmentLocation<U, W, S>,
): l is ShipmentLocationSupplier<S> => {
    return l.type === ItemLocation.Supplier;
};

type Prefix = 'from' | 'to';
type WithPrefix<P extends Prefix, L> = {
    [k in keyof L as `${P}${Capitalize<string & k>}`]: L[k];
};

type ShipmentLocationWithPrefix<P extends Prefix, U, W, S> =
    | WithPrefix<P, ShipmentLocationUser<U>>
    | WithPrefix<P, ShipmentLocationHofyWarehouse<W>>
    | WithPrefix<P, ShipmentLocationOrgWarehouse<W>>
    | WithPrefix<P, ShipmentLocationClearanceWarehouse<W>>
    | WithPrefix<P, ShipmentLocationSupplier<S>>;

type ShipmentLocationFrom<U, W, S> = ShipmentLocationWithPrefix<'from', U, W, S>;
type ShipmentLocationTo<U, W, S> = ShipmentLocationWithPrefix<'to', U, W, S>;

type ShipmentLocationFromUser<U> = WithPrefix<'from', ShipmentLocationUser<U>>;
type ShipmentLocationToUser<U> = WithPrefix<'to', ShipmentLocationUser<U>>;
type ShipmentLocationFromHofyWarehouse<W> = WithPrefix<'from', ShipmentLocationHofyWarehouse<W>>;
type ShipmentLocationToHofyWarehouse<W> = WithPrefix<'to', ShipmentLocationHofyWarehouse<W>>;
type ShipmentLocationFromOrgWarehouse<W> = WithPrefix<'from', ShipmentLocationOrgWarehouse<W>>;
type ShipmentLocationFromClearanceWarehouse<W> = WithPrefix<'from', ShipmentLocationClearanceWarehouse<W>>;
type ShipmentLocationToOrgWarehouse<W> = WithPrefix<'to', ShipmentLocationOrgWarehouse<W>>;
type ShipmentLocationToClearanceWarehouse<W> = WithPrefix<'to', ShipmentLocationClearanceWarehouse<W>>;
type ShipmentLocationFromSupplier<S> = WithPrefix<'from', ShipmentLocationSupplier<S>>;
type ShipmentLocationToSupplier<S> = WithPrefix<'to', ShipmentLocationSupplier<S>>;

export type ShipmentLocationAware<U = unknown, W = unknown, S = unknown> = ShipmentLocationFrom<U, W, S> &
    ShipmentLocationTo<U, W, S>;

export const isShipmentFromUser = <U, W, S>(
    s: ShipmentLocationAware<U, W, S> | null | undefined,
): s is ShipmentLocationFromUser<U> & ShipmentLocationTo<U, W, S> => {
    return s?.fromType === ItemLocation.WithUser;
};

export const isShipmentToUser = <U, W, S>(
    s: ShipmentLocationAware<U, W, S> | null | undefined,
): s is ShipmentLocationFrom<U, W, S> & ShipmentLocationToUser<U> => {
    return s?.toType === ItemLocation.WithUser;
};

export const isShipmentFromOrganizationWarehouse = <U, W, S>(
    s: ShipmentLocationAware<U, W, S> | null | undefined,
): s is ShipmentLocationFromOrgWarehouse<W> & ShipmentLocationTo<U, W, S> => {
    return s?.fromType === ItemLocation.OrganizationWarehouse;
};

export const isShipmentFromClearanceWarehouse = <U, W, S>(
    s: ShipmentLocationAware<U, W, S> | null | undefined,
): s is ShipmentLocationFromClearanceWarehouse<W> & ShipmentLocationTo<U, W, S> => {
    return s?.fromType === ItemLocation.ClearanceWarehouse;
};

export const isShipmentToOrganizationWarehouse = <U, W, S>(
    s: ShipmentLocationAware<U, W, S> | null | undefined,
): s is ShipmentLocationFrom<U, W, S> & ShipmentLocationToOrgWarehouse<W> => {
    return s?.toType === ItemLocation.OrganizationWarehouse;
};

export const isShipmentToClearanceWarehouse = <U, W, S>(
    s: ShipmentLocationAware<U, W, S> | null | undefined,
): s is ShipmentLocationFrom<U, W, S> & ShipmentLocationToClearanceWarehouse<W> => {
    return s?.toType === ItemLocation.ClearanceWarehouse;
};

export const isShipmentFromHofyWarehouse = <U, W, S>(
    s: ShipmentLocationAware<U, W, S> | null | undefined,
): s is ShipmentLocationFromHofyWarehouse<W> & ShipmentLocationTo<U, W, S> => {
    return s?.fromType === ItemLocation.HofyWarehouse;
};

export const isShipmentToHofyWarehouse = <U, W, S>(
    s: ShipmentLocationAware<U, W, S> | null | undefined,
): s is ShipmentLocationFrom<U, W, S> & ShipmentLocationToHofyWarehouse<W> => {
    return s?.toType === ItemLocation.HofyWarehouse;
};

export const isShipmentFromSupplier = <U, W, S>(
    s: ShipmentLocationAware<U, W, S> | null | undefined,
): s is ShipmentLocationFromSupplier<S> & ShipmentLocationTo<U, W, S> => {
    return s?.fromType === ItemLocation.Supplier;
};

export const isShipmentToSupplier = <U, W, S>(
    s: ShipmentLocationAware<U, W, S> | null | undefined,
): s is ShipmentLocationFrom<U, W, S> & ShipmentLocationToSupplier<S> => {
    return s?.toType === ItemLocation.Supplier;
};

export const isShipmentFromWarehouse = <U, W, S>(
    s: ShipmentLocationAware<U, W, S> | null | undefined,
): s is (
    | ShipmentLocationFromHofyWarehouse<W>
    | ShipmentLocationFromOrgWarehouse<W>
    | ShipmentLocationFromClearanceWarehouse<W>
) &
    ShipmentLocationTo<U, W, S> => {
    return (
        isShipmentFromHofyWarehouse(s) ||
        isShipmentFromOrganizationWarehouse(s) ||
        isShipmentFromClearanceWarehouse(s)
    );
};

export const isShipmentToWarehouse = <U, W, S>(
    s: ShipmentLocationAware<U, W, S> | null | undefined,
): s is ShipmentLocationFrom<U, W, S> &
    (
        | ShipmentLocationToHofyWarehouse<W>
        | ShipmentLocationToOrgWarehouse<W>
        | ShipmentLocationToClearanceWarehouse<W>
    ) => {
    return (
        isShipmentToHofyWarehouse(s) ||
        isShipmentToOrganizationWarehouse(s) ||
        isShipmentToClearanceWarehouse(s)
    );
};

export const isShipmentFromUserToUser = <U, W, S>(
    shipment: ShipmentLocationAware<U, W, S> | null | undefined,
): shipment is ShipmentLocationFromUser<U> & ShipmentLocationToUser<U> => {
    return isShipmentFromUser(shipment) && isShipmentToUser(shipment);
};

export const isShipmentFromUserToHofyWarehouse = <U, W, S>(
    s: ShipmentLocationAware<U, W, S> | null | undefined,
): s is ShipmentLocationFromUser<U> & ShipmentLocationToHofyWarehouse<W> => {
    return isShipmentFromUser(s) && isShipmentToHofyWarehouse(s);
};

export const isShipmentFromHofyWarehouseToUser = <U, W, S>(
    s: ShipmentLocationAware<U, W, S> | null | undefined,
): s is ShipmentLocationFromHofyWarehouse<W> & ShipmentLocationToUser<U> => {
    return isShipmentFromHofyWarehouse(s) && isShipmentToUser(s);
};

export const isShipmentFromUserToOrganizationWarehouse = <U, W, S>(
    s: ShipmentLocationAware<U, W, S> | null | undefined,
): s is ShipmentLocationFromUser<U> & ShipmentLocationToOrgWarehouse<W> => {
    return isShipmentFromUser(s) && isShipmentToOrganizationWarehouse(s);
};

export const isShipmentFromOrganizationWarehouseToUser = <U, W, S>(
    s: ShipmentLocationAware<U, W, S> | null | undefined,
): s is ShipmentLocationFromOrgWarehouse<W> & ShipmentLocationToUser<U> => {
    return isShipmentFromOrganizationWarehouse(s) && isShipmentToUser(s);
};

export const isShipmentFromUserToWarehouse = <U, W, S>(
    s: ShipmentLocationAware<U, W, S> | null | undefined,
): s is ShipmentLocationFromUser<U> &
    (ShipmentLocationToHofyWarehouse<W> | ShipmentLocationToOrgWarehouse<W>) => {
    return isShipmentFromUser(s) && isShipmentToWarehouse(s);
};

export const isShipmentFromWarehouseToUser = <U, W, S>(
    s: ShipmentLocationAware<U, W, S> | null | undefined,
): s is (ShipmentLocationFromHofyWarehouse<W> | ShipmentLocationFromOrgWarehouse<W>) &
    ShipmentLocationToUser<U> => {
    return isShipmentFromWarehouse(s) && isShipmentToUser(s);
};

export const isShipmentFromSupplierToUser = <U, W, S>(
    s: ShipmentLocationAware<U, W, S> | null | undefined,
): s is ShipmentLocationFromSupplier<S> & ShipmentLocationToUser<U> => {
    return isShipmentFromSupplier(s) && isShipmentToUser(s);
};

export const getShipmentFromLocation = <U = unknown, W = unknown, S = unknown>(
    shipment: ShipmentLocationAware<U, W, S>,
): ShipmentLocation<U, W, S> => {
    return {
        type: shipment.fromType,
        address: shipment.fromAddress,
        ...(isShipmentFromUser(shipment) && {
            user: shipment.fromUser,
        }),
        ...(isShipmentFromWarehouse(shipment) && {
            warehouse: shipment.fromWarehouse,
        }),
        ...(isShipmentFromSupplier(shipment) && {
            supplier: shipment.fromSupplier,
        }),
    } as ShipmentLocation<U, W, S>;
};

export const getShipmentToLocation = <U = unknown, W = unknown, S = unknown>(
    shipment: ShipmentLocationAware<U, W, S>,
): ShipmentLocation<U, W, S> => {
    return {
        type: shipment.toType,
        address: shipment.toAddress,
        ...(isShipmentToUser(shipment) && {
            user: shipment.toUser,
        }),
        ...(isShipmentToWarehouse(shipment) && {
            warehouse: shipment.toWarehouse,
        }),
        ...(isShipmentToSupplier(shipment) && {
            supplier: shipment.toSupplier,
        }),
    } as ShipmentLocation<U, W, S>;
};
