import { FloatingFocusManager, type Placement, useMergeRefs } from '@floating-ui/react';
import { motion, type MotionStyle } from 'framer-motion';
import React, { forwardRef } from 'react';
import styled from 'styled-components';

import { ZIndex } from '@hofy/theme';

import { placementToOrigin } from '../../helpers/Css';
import { Box, BoxProps } from '../base';
import { PopoverState } from './hooks/usePopover';

interface PopoverContentProps extends BoxProps {
    state: PopoverState;
    style?: MotionStyle;
    floatingProps?: any;
    placement: Placement;
}

export const PopoverContent = forwardRef<HTMLDivElement, PopoverContentProps>(
    ({ state, style, floatingProps, children, placement, ...props }, propRef) => {
        const ref = useMergeRefs([state.data.refs.setFloating, propRef]);

        return (
            <FloatingFocusManager context={state.data.context} modal={false}>
                <WrapperBox column ref={ref} style={style} {...floatingProps}>
                    <Box
                        ref={ref}
                        as={motion.div}
                        initial={{ scale: 0.9, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        exit={{ scale: 0.9, opacity: 0 }}
                        transition={{ duration: 0.15 }}
                        style={placementToOrigin(placement)}
                        {...props}
                    >
                        {children}
                    </Box>
                </WrapperBox>
            </FloatingFocusManager>
        );
    },
);

const WrapperBox = styled(Box)`
    z-index: ${ZIndex.Popover};
`;
