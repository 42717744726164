import React, { FC, useState } from 'react';

import {
    AdminItemFilter,
    allAdminItemFilters,
    useExportItems,
    useHofyWarehousesQuery,
    useItems,
} from '@hofy/api-admin';
import {
    allFilterableItemStatuses,
    allItemGrades,
    allOwnerships,
    allParentProductCategories,
    ItemGrade,
    ItemLocation,
    ItemStatus,
    Ownership,
    useTrItemLocation,
    useTrItemStatus,
    useTrOwnership,
} from '@hofy/api-shared';
import { ExportButton, Page } from '@hofy/common';
import { UUID } from '@hofy/global';
import { useStructMemo } from '@hofy/hooks';
import { ProductCategoryTree } from '@hofy/product';
import { Box, Button, HiddenScroll, PageHeader, SearchInput, SectionTitle2, SvgIcon } from '@hofy/ui';

import { BlockFilterButton } from '../../components/design/blockFilters/BlockFilterButton';
import { BlockFilterChipContainer } from '../../components/design/blockFilters/BlockFilterChipContainer';
import { BlockFilterContainer } from '../../components/design/blockFilters/BlockFilterContainer';
import { EnumBlockFilter } from '../../components/design/blockFilters/EnumBlockFilter';
import { EnumMultiBlockFilter } from '../../components/design/blockFilters/EnumMultiBlockFilter';
import { useBlockFilters } from '../../components/design/blockFilters/hooks/useBlockFilters';
import { OrganizationBlockFilter } from '../../components/domain/filters/OrganizationBlockFilter';
import { useAdminItemFilters } from '../../store/items/useAdminItemFilters';
import { useTrItemGrade } from '../../store/items/useTrItemGrade';
import { useOrganizationsRefsQuery } from '../../store/organizations/useOrganizationsQuery';
import { useTrItemFilterType } from '../../store/products/useTrItemFilterType';
import { ItemsActiveFilterChips } from './filters/ItemsActiveFilterChips';
import { WarehouseBlockFilter } from './filters/WarehouseBlockFilter';
import { ItemsTable } from './ItemsTable';

interface StockLevelsPageProps {
    onOpenNewItem(): void;
    onOpenItemDetails(id: UUID): void;
}

export const ItemsPage: FC<StockLevelsPageProps> = ({ onOpenNewItem, onOpenItemDetails }) => {
    const {
        filters,
        filtersUi,
        filterCount,
        setSearch,
        setCategory,
        setWarehouseId,
        setOrganization,
        setItemStatus,
        setLocation,
        setGrade,
        setFilterType,
        setOwnership,
    } = useAdminItemFilters();

    const { items, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } = useItems(filters);

    const [orgSearchQuery, setOrgSearchQuery] = useState('');
    const { data: organizations } = useOrganizationsRefsQuery(filters.organization, orgSearchQuery);
    const { data: allWarehouses } = useHofyWarehousesQuery();

    const trItemFilterType = useTrItemFilterType();
    const trItemStatus = useTrItemStatus();
    const trItemLocation = useTrItemLocation();
    const trItemGrade = useTrItemGrade();
    const trOwnership = useTrOwnership();
    const { exportItems } = useExportItems(filters);

    const { showFilters, toggleShowFilters, filterElRef } = useBlockFilters();

    const itemsScrollDetails = useStructMemo({
        hasMore: hasNextPage,
        isLoading: isLoading,
        isLoadingMore: isFetchingNextPage,
        loadMore: fetchNextPage,
    });

    return (
        <Page>
            <PageHeader
                title='Items'
                rightSlot={
                    <>
                        <ExportButton onClick={exportItems} />
                        <SearchInput
                            value={filters.search || ''}
                            onChange={setSearch}
                            placeholder='SKU, MPN, PO ref, Product or User'
                            autoFocus
                        />
                        <BlockFilterButton
                            onClick={toggleShowFilters}
                            isOpened={showFilters}
                            count={filterCount}
                        />
                        <Button leftIcon={SvgIcon.Add} label='Add new item' onClick={onOpenNewItem} />
                    </>
                }
            />
            <BlockFilterContainer ref={filterElRef} show={showFilters}>
                <OrganizationBlockFilter
                    selected={filters.organization || []}
                    onChange={setOrganization}
                    organizations={organizations}
                    searchQuery={orgSearchQuery}
                    setSearchQuery={setOrgSearchQuery}
                />

                <EnumMultiBlockFilter<ItemGrade>
                    title='Grade'
                    icon={SvgIcon.Star}
                    selected={filters.grade || []}
                    onChange={setGrade}
                    items={allItemGrades}
                    renderItem={trItemGrade}
                />
                <EnumMultiBlockFilter<ItemStatus>
                    title='Item status'
                    icon={SvgIcon.AlertCircle}
                    selected={filters.status || []}
                    onChange={setItemStatus}
                    items={allFilterableItemStatuses}
                    renderItem={trItemStatus}
                />
                <WarehouseBlockFilter
                    selected={filters.warehouseId}
                    onChange={setWarehouseId}
                    warehouses={allWarehouses}
                />
                <EnumMultiBlockFilter<ItemLocation>
                    title='Item location'
                    icon={SvgIcon.Globe}
                    selected={filters.location}
                    onChange={setLocation}
                    items={[
                        ItemLocation.WithUser,
                        ItemLocation.AtWarehouse,
                        ItemLocation.WithCourier,
                        ItemLocation.WriteOff,
                    ]}
                    renderItem={trItemLocation}
                />
                <EnumBlockFilter<AdminItemFilter>
                    title='Item filter'
                    icon={SvgIcon.Filter}
                    selected={filters.filter}
                    onChange={setFilterType}
                    items={allAdminItemFilters}
                    renderItem={trItemFilterType}
                />
                <EnumBlockFilter<Ownership>
                    title='Ownership'
                    icon={SvgIcon.Help}
                    selected={filters.ownership}
                    onChange={setOwnership}
                    items={allOwnerships}
                    renderItem={trOwnership}
                />
            </BlockFilterContainer>
            <BlockFilterChipContainer show={filterCount > 0}>
                <ItemsActiveFilterChips
                    filters={filters}
                    warehouses={allWarehouses}
                    organizations={organizations}
                    onGradeChange={setGrade}
                    onWarehouseIdChange={setWarehouseId}
                    onOrganizationChange={setOrganization}
                    onFilterTypeChange={setFilterType}
                    onItemStatusChange={setItemStatus}
                    onItemLocationChange={setLocation}
                    onOwnershipChange={setOwnership}
                />
            </BlockFilterChipContainer>
            <Box relative flex='auto' row alignItems='stretch'>
                <HiddenScroll paddingHorizontal={30} paddingVertical={24} width={300} borderRight>
                    <SectionTitle2 paddingBottom={24} paddingLeft={10}>
                        Category
                    </SectionTitle2>
                    <ProductCategoryTree
                        category={filtersUi.category}
                        parentProductCategories={allParentProductCategories}
                        onChange={setCategory}
                    />
                </HiddenScroll>
                <ItemsTable
                    items={items}
                    onOpenItem={onOpenItemDetails}
                    infinityScroll={itemsScrollDetails}
                />
            </Box>
        </Page>
    );
};
