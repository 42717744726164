import React, { FC } from 'react';

import { DocumentFileDto } from '@hofy/api-shared';
import { UUID } from '@hofy/global';
import { formatDateTime } from '@hofy/helpers';
import { AsyncButton, BaseTable, Paragraph3, Placeholder, SvgIcon, SvgIllustration } from '@hofy/ui';

interface DocumentFilesProps {
    files: DocumentFileDto[];
    download(entityId: UUID, fileId: UUID): void;
    isDownloading: boolean;
    isDownloadError: boolean;
}

export const DocumentFilesTable: FC<DocumentFilesProps> = ({
    files,
    download,
    isDownloading,
    isDownloadError,
}) => {
    return (
        <BaseTable
            data={files}
            toKey={file => file.id}
            emptyContent={<Placeholder illustration={SvgIllustration.List} title='No files' />}
            height='auto'
            headerHorizontalPadding={40}
            rowHorizontalPadding={30}
            columns={[
                {
                    id: 'createdAt',
                    header: 'Created at',
                    flexGrow: 1,
                    width: 180,
                    renderer: ({ createdAt }) => formatDateTime(createdAt),
                },
                {
                    id: 'filename',
                    header: 'Filename',
                    flexGrow: 1,
                    width: 180,
                    renderer: ({ name }) => <Paragraph3>{name}</Paragraph3>,
                },
                {
                    id: 'download',
                    header: 'Download',
                    flexGrow: 1,
                    width: 180,
                    renderer: ({ entityId, id }) => (
                        <AsyncButton
                            isLoading={isDownloading}
                            type='secondary'
                            leftIcon={SvgIcon.Download}
                            label='Download'
                            onClick={() => !isDownloading && download(entityId, id)}
                            isError={isDownloadError}
                        />
                    ),
                },
            ]}
        />
    );
};
