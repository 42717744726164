import React, { FC, useEffect } from 'react';

import { useTrAssetGradeWithCondition, WarehouseRefDto } from '@hofy/api-shared';
import {
    Box,
    FormFieldRecord,
    FormInput,
    IconButton,
    InnerCard,
    LabeledText,
    Paragraph3,
    SvgIcon,
} from '@hofy/ui';

import { AssignedBulkStorageItemFormData } from '../../../../../store/bulkStorageOrders/types/AssignBulkStorageOrderItemFormData';
import { useItemByItemCode } from '../../../../../store/items/useItemByItemCode';

interface BulkStorageOrderItemDetailsProps {
    itemForm: FormFieldRecord<AssignedBulkStorageItemFormData>;
    index: number;
    deleteItem(index: number): void;
}

export const BulkStorageOrderItemDetails: FC<BulkStorageOrderItemDetailsProps> = ({
    itemForm,
    index,
    deleteItem,
}) => {
    const { data: searchedItem, isLoading } = useItemByItemCode(itemForm.itemCode.value ?? null);
    const trAssetGrade = useTrAssetGradeWithCondition();

    useEffect(() => {
        if (!searchedItem || isLoading) {
            itemForm.itemDto.setValue(null);
        } else {
            itemForm.itemDto.setValue(searchedItem);
        }
    }, [isLoading, searchedItem]);
    return (
        <InnerCard padding={20} gap={20} row fullWidth alignItems='flex-start'>
            <Box fullHeight justify='flex-start'>
                <Paragraph3>{index + 1}.</Paragraph3>
            </Box>
            <FormInput
                label='Item code'
                api={itemForm.itemCode}
                autoFocus={index === 0}
                isRequired
                nullable
                helperText={isLoading ? 'Loading item details...' : undefined}
            />
            {isLoading && <Paragraph3>Loading item details...</Paragraph3>}
            {searchedItem && (
                <>
                    <LabeledText label='Public item id' content={searchedItem.publicId} />
                    <LabeledText label='Item condition' content={trAssetGrade(searchedItem.grade)} />
                </>
            )}

            <Box flex={1} />
            <IconButton icon={SvgIcon.Cross} onClick={() => deleteItem(index)} />
        </InnerCard>
    );
};
