import { Months, UUID } from '@hofy/global';

import { AcquisitionType } from './AcquisitionType';
import { PaymentSchema } from './PaymentSchema';
import { getAvailableRentalTerms, RentalTerm } from './RentalTerm';

export type BaseContractSettingDto =
    | {
          acquisitionType: AcquisitionType.Rental;
          rentalTerm: RentalTerm;
          paymentSchema: PaymentSchema;
          servicePaymentSchema: PaymentSchema;
          warrantyPaymentSchema: PaymentSchema;
      }
    | {
          acquisitionType: AcquisitionType.Purchase;
          rentalTerm: null;
          paymentSchema: null;
          servicePaymentSchema: PaymentSchema;
          warrantyPaymentSchema: PaymentSchema;
      }
    | {
          acquisitionType: AcquisitionType.Reuse;
          rentalTerm: null;
          paymentSchema: null;
          servicePaymentSchema: PaymentSchema;
          warrantyPaymentSchema: PaymentSchema;
      };

export type ContractSettingDto = BaseContractSettingDto & {
    serviceBundleId: AddonId | null;
    warrantyBundleId: AddonId | null;
    serviceDuration: Months | null;
};

export type AddonId = UUID;

export const isAllowedSchema = (setting: ContractSettingDto, allowed: PaymentSchema[]): boolean => {
    switch (setting.acquisitionType) {
        case AcquisitionType.Reuse:
        case AcquisitionType.Purchase:
            return true;
        case AcquisitionType.Rental:
            return allowed.includes(setting.paymentSchema);
    }
};

export const isAllowedRentalTerm = (setting: ContractSettingDto, allowed: RentalTerm[]): boolean => {
    switch (setting.acquisitionType) {
        case AcquisitionType.Reuse:
        case AcquisitionType.Purchase:
            return true;
        case AcquisitionType.Rental:
            return allowed.includes(setting.rentalTerm);
    }
};

export const isValidPaymentSchemaForRentalTerm = ({
    rentalTerm,
    paymentSchema,
    acquisitionType,
}: BaseContractSettingDto) => {
    if ([AcquisitionType.Purchase, AcquisitionType.Reuse].includes(acquisitionType)) {
        return true;
    }

    if (paymentSchema === PaymentSchema.Annual) {
        return rentalTerm === RentalTerm.Rental24Months || rentalTerm === RentalTerm.Rental36Months;
    }

    if (paymentSchema === PaymentSchema.Deposit12Months) {
        return rentalTerm !== RentalTerm.Rental6Months;
    }

    return true;
};

export const getAllowedRentalTermsForPaymentSchema = (
    unbundlingEnabled: boolean,
    paymentSchema: PaymentSchema | null,
    organizationAllowedRentalTerms: RentalTerm[],
): RentalTerm[] => {
    const availableRentalTerms = getAvailableRentalTerms(unbundlingEnabled);
    return paymentSchema === PaymentSchema.Upfront ? availableRentalTerms : organizationAllowedRentalTerms;
};
