import React, { FC } from 'react';

import { PostAddressView } from '@hofy/address';
import { PurchaseOrderDetailsDto } from '@hofy/api-admin';
import { formatDate } from '@hofy/helpers';
import { Box, CountryLabel, FormGridRow, Labeled, LabeledText } from '@hofy/ui';

import { getTrackingLink } from '../../../../shipmentSlideout/components/CourierDetails';

interface PurchaseOrderDeliveryProps {
    purchaseOrder: PurchaseOrderDetailsDto;
}

export const PurchaseOrderDelivery: FC<PurchaseOrderDeliveryProps> = ({ purchaseOrder }) => {
    return (
        <FormGridRow columns={4}>
            <LabeledText
                label='Est. Receipt'
                content={purchaseOrder.estimatedReceiveOn ? formatDate(purchaseOrder.estimatedReceiveOn) : ''}
            />
            <Box>
                <Labeled
                    label='From Country'
                    content={<CountryLabel country={purchaseOrder.fromAddress?.country || null} />}
                    marginBottom={20}
                />
                <LabeledText
                    label='From Address'
                    content={<PostAddressView address={purchaseOrder.fromAddress} />}
                />
            </Box>
            <Box>
                <Labeled
                    label='To Country'
                    content={<CountryLabel country={purchaseOrder.toAddress?.country || null} />}
                    marginBottom={20}
                />
                <LabeledText
                    label='To Address'
                    content={<PostAddressView address={purchaseOrder.toAddress} />}
                />
            </Box>
            <Box>
                <LabeledText
                    label='Tracking Reference'
                    content={purchaseOrder.courierReference}
                    marginBottom={20}
                />
                <Labeled label='Tracking link' content={getTrackingLink(purchaseOrder.trackingLink)} />
            </Box>
        </FormGridRow>
    );
};
