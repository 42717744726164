import React, { FC } from 'react';

import { ItemDto } from '@hofy/api-admin';
import { ItemStatus, useTrItemStatus, useTrReplacementResult } from '@hofy/api-shared';
import { allReplacementResults, ReplacementResult } from '@hofy/api-shared';
import {
    Alert,
    FormContainer,
    FormModal,
    FormSelect,
    ModalContent,
    ModalFooter,
    ModalHeader,
    SubmitButton,
} from '@hofy/ui';

import { CancelButton } from '../../../components/design/button/CancelButton';
import { FormHofySubsidiarySelect } from '../../../components/domain/hofySubsidiaries/FormHofySubsidiarySelect';
import { WarehouseBinSelector } from '../../../components/domain/warehouses/WarehouseBinSelector';
import { WarehouseDropdown } from '../../../components/domain/warehouses/WarehouseDropdown';
import { useAdminI18n } from '../../../i18n/useAdminI18n';
import { useUpdateReplacementResult } from '../../../store/items/useUpdateReplacementResult';

interface UpdateItemReplacementResultModalProps {
    item: ItemDto;
    onCancel(): void;
}

export const UpdateItemReplacementResultModal: FC<UpdateItemReplacementResultModalProps> = ({
    item,
    onCancel,
}) => {
    const { form, isLoading, isError } = useUpdateReplacementResult(item, onCancel);
    const trReplacementResult = useTrReplacementResult();
    const trItemStatus = useTrItemStatus();
    const { tr } = useAdminI18n();
    const selectedReplacementResult = form.fields.result.value;
    const replacementWriteOffStatuses = [
        ItemStatus.Stolen,
        ItemStatus.BrokenByUser,
        ItemStatus.BrokenInTransit,
        ItemStatus.LostInTransit,
        ItemStatus.SupplierReturn,
    ];

    return (
        <FormModal
            onClose={form.discard}
            onSubmit={form.submit}
            isLoading={isLoading}
            isError={isError}
            width={600}
        >
            <ModalHeader title={tr('item-update-replacement-result-modal.title')} />
            <ModalContent>
                <FormContainer>
                    <Alert
                        title={tr('item-update-replacement-result-modal.alert.title')}
                        description={tr('item-update-replacement-result-modal.alert.description')}
                        type='warning'
                    />
                    <FormSelect
                        isRequired
                        options={allReplacementResults}
                        toLabel={trReplacementResult}
                        toText={trReplacementResult}
                        label={tr('item-update-replacement-result-modal.replacement-select')}
                        api={form.fields.result}
                    />
                    <FormHofySubsidiarySelect
                        api={form.fields.hofySubsidiaryId}
                        label='Hofy subsidiary'
                        isRequired
                        nullable
                    />
                    {selectedReplacementResult === ReplacementResult.WriteOff && (
                        <FormSelect
                            isRequired
                            options={replacementWriteOffStatuses}
                            toLabel={trItemStatus}
                            toText={trItemStatus}
                            label={tr('item-update-replacement-result-modal.status-select')}
                            api={form.fields.status}
                        />
                    )}
                    {selectedReplacementResult === ReplacementResult.BackToStock && (
                        <>
                            <WarehouseDropdown
                                label='To warehouse'
                                isRequired
                                onChange={warehouseId =>
                                    form.fields.warehouseId.setValue(warehouseId ?? null)
                                }
                                value={form.values.warehouseId}
                                isError={form.errors.warehouseId}
                            />
                            {form.fields.warehouseId.value && (
                                <WarehouseBinSelector
                                    api={form.fields.warehouseBinIdentifier}
                                    warehouse={{
                                        id: form.fields.warehouseId.value,
                                        name: '',
                                    }}
                                    isAddBinPromptEnabled
                                />
                            )}
                        </>
                    )}
                </FormContainer>
            </ModalContent>
            <ModalFooter>
                <CancelButton onClick={onCancel} />
                <SubmitButton label={tr('item-update-replacement-result-modal.button.submit')} />
            </ModalFooter>
        </FormModal>
    );
};
