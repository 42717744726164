import { FloatingPortal, type Placement } from '@floating-ui/react';
import { AnimatePresence } from 'framer-motion';
import React, { ReactNode } from 'react';

import { TestKeyAware } from '../../types';
import { BoxProps } from '../base';
import { useDropdown } from './hooks/useDropdown';
import { DropdownContent } from './shared/DropdownContent';
import { DropdownTrigger } from './shared/DropdownTrigger';
import { ContentWidth } from './types/InteractiveListTypes';

interface DropdownProps extends BoxProps, TestKeyAware {
    trigger?: ReactNode;
    children?: ReactNode;

    open: boolean;
    onDismiss(): void;

    asChild?: boolean;
    placement?: Placement;
    contentWidth?: ContentWidth;
    flipPadding?: number;
}

export const Dropdown = ({
    trigger,
    asChild,
    children,
    open,
    onDismiss,
    placement,
    contentWidth,
    flipPadding,
    ...rest
}: DropdownProps) => {
    const { data, interactions } = useDropdown({
        open,
        placement,
        contentWidth,
        flipPadding,
        onChange: val => !val && onDismiss(),
    });

    return (
        <>
            <DropdownTrigger
                referenceProps={interactions.getReferenceProps}
                setReference={data.refs.setReference}
                asChild={asChild}
                testKey='dropdown-trigger'
            >
                {trigger}
            </DropdownTrigger>

            <AnimatePresence>
                {open && (
                    <FloatingPortal>
                        <DropdownContent
                            ref={data.refs.setFloating}
                            style={data.floatingStyles}
                            floatingProps={interactions.getFloatingProps()}
                            placement={data.placement}
                            {...rest}
                        >
                            {children}
                        </DropdownContent>
                    </FloatingPortal>
                )}
            </AnimatePresence>
        </>
    );
};
