import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
    organizationAddonConfigCacheKey,
    OrganizationAddonConfigDetailsDto,
    organizationAddonConfigService,
} from '@hofy/api-admin';
import { PaymentSchema } from '@hofy/api-shared';
import { Months, numberToPercent, percentToNumber, UUID } from '@hofy/global';
import { isRequired, isRequiredIf, isValidPercent, useForm, useToast, validator } from '@hofy/ui';

interface UpdateAddonConfigurationForm {
    isActive: boolean;
    discount: number;
    assetAgeMax: Months | null;
    enrollmentDuration: Months | null;
    enrollmentPaymentSchema: PaymentSchema | null;
}

export const useUpdateOrganizationAddonConfig = (
    organizationId: UUID,
    addonId: UUID,
    configuration: OrganizationAddonConfigDetailsDto,
    onSuccess: () => void,
) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();
    const { mutate, isPending, isError } = useMutation({
        mutationFn: ({
            isActive: active,
            discount,
            assetAgeMax,
            enrollmentDuration,
            enrollmentPaymentSchema,
        }: UpdateAddonConfigurationForm) =>
            organizationAddonConfigService.upsertOrganizationAddonConfiguration(organizationId, addonId, {
                active,
                discount: numberToPercent(discount),
                assetAgeMax,
                enrollmentDuration,
                enrollmentPaymentSchema,
            }),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [organizationAddonConfigCacheKey, organizationId],
            });
            queryClient.invalidateQueries({
                queryKey: [organizationAddonConfigCacheKey, organizationId, addonId],
            });
            showToast({
                type: 'positive',
                message: 'Addon configuration successfully saved',
            });
            onSuccess();
        },
    });

    const form = useForm<UpdateAddonConfigurationForm>({
        initial: {
            isActive: configuration.isActive,
            discount: percentToNumber(configuration.discount),
            assetAgeMax: configuration.assetAgeMax,
            enrollmentDuration: configuration.enrollmentDuration,
            enrollmentPaymentSchema: configuration.enrollmentPaymentSchema,
        },
        validate: validator<UpdateAddonConfigurationForm>({
            discount: [
                isRequired('Discount is required'),
                isValidPercent('Discount must be a valid percentage'),
            ],
            enrollmentDuration: [
                isRequiredIf(
                    ({ isActive }) => isActive && !configuration.isPublic,
                    'Enrollment duration is required',
                ),
            ],
            enrollmentPaymentSchema: [
                isRequiredIf(
                    ({ isActive }) => isActive && !configuration.isPublic,
                    'Enrollment payment schema is required',
                ),
            ],
        }),
        onSubmit: mutate,
    });

    return {
        form,
        isPending,
        isError,
    };
};
