import { getEnumValues } from '@hofy/global';

import { PlatformTier, unbundledPlatformTiers } from './PlatformTier';

export enum OrganizationType {
    Standard = 'standard',
    Demo = 'demo',
    Partner = 'partner',
    DeelIT = 'deelit',
}

export const prodOrganizationType = [OrganizationType.Standard, OrganizationType.Partner];
export const allOrganizationTypes = getEnumValues<OrganizationType>(OrganizationType);

const organizationTypePlatformTiers = {
    [OrganizationType.Standard]: unbundledPlatformTiers,
    [OrganizationType.Demo]: [PlatformTier.OrgBasedPro],
    [OrganizationType.Partner]: [PlatformTier.UserBasedPro],
    [OrganizationType.DeelIT]: [PlatformTier.OrgBasedPro],
};

export const getPlatformTierOptions = function (
    organizationType: OrganizationType,
    organizationContractsEnabled: boolean,
): PlatformTier[] {
    const options = organizationTypePlatformTiers[organizationType];
    const showPerSeat = organizationContractsEnabled && organizationType === OrganizationType.DeelIT;
    return [...options, ...(showPerSeat ? [PlatformTier.PerSeat] : [])];
};
