import React, { FC } from 'react';

import { deelStatusColors, DeelStatusDto, DeelSyncStatus } from '@hofy/api-admin';
import { Color } from '@hofy/theme';
import { Badge, Paragraph3, Tooltip } from '@hofy/ui';

import { useTrDeelSyncStatus } from '../../../store/invoices/useTrDeelSyncStatus';

interface DeelStatusChipProps {
    status: DeelStatusDto;
}

export const DeelStatusChip: FC<DeelStatusChipProps> = ({ status }) => {
    const trStatus = useTrDeelSyncStatus();
    if (!status.status) {
        return <Badge color='grey' label='Not Deel IT' />;
    }

    if (status.status === DeelSyncStatus.Error) {
        return (
            <Tooltip
                bodySlot={
                    <Paragraph3 color={Color.ContentInvertedPrimary} wordBreak>
                        {status.errorReason}
                    </Paragraph3>
                }
            >
                <Badge color={deelStatusColors[status.status]} label={trStatus(status.status)} />
            </Tooltip>
        );
    }
    return <Badge color={deelStatusColors[status.status]} label={trStatus(status.status)} />;
};
