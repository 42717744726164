import { DateTime } from 'luxon';
import React, { FC } from 'react';
import { type ReactDatePickerCustomHeaderProps } from 'react-datepicker';

import { Box } from '../../base';
import { IconButton, SvgIcon } from '../../icon';
import { MonthYearDropdowns } from './MonthYearDropdowns';

interface DatepickerHeaderProps extends ReactDatePickerCustomHeaderProps {
    showMonths?: boolean;
}

export const DatepickerHeader: FC<DatepickerHeaderProps> = ({
    date,
    showMonths,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
    decreaseMonth,
    increaseMonth,
    changeYear,
    changeMonth,
}) => {
    return (
        <Box row flex={1} justify='space-between' marginBottom={16}>
            <IconButton
                disabled={prevMonthButtonDisabled}
                icon={SvgIcon.ChevronLeft}
                onClick={decreaseMonth}
                size={24}
            />
            <Box flex={1} centered>
                <MonthYearDropdowns
                    date={DateTime.fromJSDate(date)}
                    onYearChange={changeYear}
                    onMonthChange={changeMonth}
                    showMonths={showMonths}
                />
            </Box>
            <IconButton
                disabled={nextMonthButtonDisabled}
                icon={SvgIcon.ChevronRight}
                onClick={increaseMonth}
                size={24}
            />
        </Box>
    );
};
