import {
    AuditableDto,
    HofyWarehouseType,
    isHofyWarehouse,
    isOrganizationWarehouse,
    OrganizationWarehouseType,
    Ownership,
    UserRefDto,
    WarehouseDto,
} from '@hofy/api-shared';
import { Country, UUID } from '@hofy/global';

import { ClearancePartnerDto } from '../../../clearance/service/types/ClearancePartnerDto';

interface HofyWarehouseDto {
    type: HofyWarehouseType;
    hofySubsidiaryId: UUID;
    countries: Country[];
    isWarehouseBinRequired: boolean;
    dataErasureSupported: boolean;
    byodSupported: boolean;
}

interface ClearanceWarehouseDto {
    clearancePartner: ClearancePartnerDto;
}

interface OrganizationWarehouseDto {
    type: OrganizationWarehouseType;
    organizationId: UUID;
    primaryManager: UserRefDto | null;
    secondaryManager: UserRefDto | null;
}

interface WarehouseDetailsDto extends AuditableDto, WarehouseDto {
    hofyWarehouse: HofyWarehouseDto | null;
    organizationWarehouse: OrganizationWarehouseDto | null;
    clearanceWarehouse: ClearanceWarehouseDetailsDto | null;
}

export type HofyWarehouseDetailsDto = WarehouseDetailsDto & {
    ownership: Ownership.Hofy;
    hofyWarehouse: HofyWarehouseDto;
    organizationWarehouse: null;
    clearanceWarehouse: null;
};

export type ClearanceWarehouseDetailsDto = WarehouseDetailsDto & {
    ownership: Ownership.Hofy;
    hofyWarehouse: null;
    organizationWarehouse: null;
    clearanceWarehouse: ClearanceWarehouseDto;
};

export type OrganizationWarehouseDetailsDto = WarehouseDetailsDto & {
    ownership: Ownership.Organization;
    hofyWarehouse: null;
    organizationWarehouse: OrganizationWarehouseDto;
    clearanceWarehouse: null;
};

export type WarehouseDetailsUnionDto = HofyWarehouseDetailsDto | OrganizationWarehouseDetailsDto;

export const isAdminHofyWarehouse = (
    warehouse?: WarehouseDetailsUnionDto | null,
): warehouse is HofyWarehouseDetailsDto => {
    return isHofyWarehouse(warehouse);
};

export const isAdminOrganizationWarehouse = (
    warehouse?: WarehouseDetailsUnionDto | null,
): warehouse is OrganizationWarehouseDetailsDto => {
    return isOrganizationWarehouse(warehouse);
};

export const isMainHofyWarehouse = (
    warehouse?: WarehouseDetailsUnionDto | null,
): warehouse is HofyWarehouseDetailsDto => {
    return isAdminHofyWarehouse(warehouse) && warehouse.hofyWarehouse.type === HofyWarehouseType.Main;
};

export const is3rdPartyHofyWarehouse = (
    warehouse?: WarehouseDetailsUnionDto | null,
): warehouse is HofyWarehouseDetailsDto => {
    return (
        isAdminHofyWarehouse(warehouse) &&
        (warehouse.hofyWarehouse.type === HofyWarehouseType.Virtual ||
            warehouse.hofyWarehouse.type === HofyWarehouseType.Partner)
    );
};
