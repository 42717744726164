export const organizationDeelUpdateI18n = {
    'organization-page.deel-update.modal.unlink.title': 'Unlink Partner',
    'organization-page.deel-update.modal.unlink.subtitle':
        'Are you sure you want to unlink the organization from the parent partner organization?',
    'organization-page.deel-update.modal.unlink.confirm': 'Unlink',
    'organization-page.deel-update.modal.unlink.cancel': 'Cancel',
    'organization-page.deel-update.modal.unlink.alert.title':
        'This will unlink the organization from the parent partner organization',
    'organization-page.deel-update.modal.unlink.alert.description': 'This is an irreversible operation',
    'organization-page.deel-update.modal.unlink': 'Unlink from partner',
    'organization-page.deel-update.modal.partner': 'Partner',
    'organization-page.deel-update.modal.title': 'Update Deel data',
    'organization-page.deel-update.modal.confirm': 'Save',
    'organization-page.deel-update.modal.cancel': 'Cancel',
    'organization-page.deel-update.modal.form.deel-id': 'Deel Id',
    'organization-page.deel-update.modal.form.deel-id-activate-alert-title':
        'Organization is missing following criteria to activate Deel IT',
    'organization-page.deel-update.modal.form.deel-id-activate-alert-unbundle-description':
        'Organization should be unbundled',
    'organization-page.deel-update.modal.form.deel-id-activate-alert-partner-description':
        'Organization should not be linked to a partner',
    'organization-page.deel-update.modal.form.it-sync-data': 'Sync data between Deel and Hofy',
    'organization-page.deel-update.modal.hris-title': 'Organization is connected to a HR system',
    'organization-page.deel-update.modal.hris-description':
        'Please disconnect it before linking the organization to Deel IT',
};
