import React, { FC, useMemo } from 'react';
import styled, { keyframes } from 'styled-components';

import { PurchaseOrderItemDto } from '@hofy/api-admin';
import { Color } from '@hofy/theme';
import { Box, FormTextarea, UseForm } from '@hofy/ui';

import {
    ReceivePurchaseOrderItemDetailFormData,
    ReceivePurchaseOrderItemFormData,
} from '../../../../../store/purchaseOrders/types/ReceivePurchaseOrderItemFormData';
import { useReceivePurchaseOrderItems } from '../../../../../store/purchaseOrders/useReceivePurchaseOrderItems';
import { PurchaseOrderItemDescription, PurchaseOrderItemHeader } from '../PurchaseOrderItemTypography';
import { ItemUnitDetails } from './ItemUnitDetails';

interface ItemsScanFormProps {
    form: UseForm<ReceivePurchaseOrderItemFormData>;
    item: PurchaseOrderItemDto;
    updateUnit(index: number, data: Partial<ReceivePurchaseOrderItemDetailFormData>): void;
    deleteUnit(index: number): void;
}

export const ItemsScanForm: FC<ItemsScanFormProps> = ({ form, item, updateUnit, deleteUnit }) => {
    const { isUnitEmpty } = useReceivePurchaseOrderItems(() => {}, item.variant.product.category);

    const scannedQuantity = useMemo(() => {
        return form.values.receivedItems.reduce((acc, val) => (isUnitEmpty(val) ? acc : acc + 1), 0);
    }, [form.values.receivedItems]);

    return (
        <Box paddingTop={20} column fullHeight gap={30}>
            <Wrapper padding={20} row fullWidth gap={30}>
                <Box flex={1}>
                    <PurchaseOrderItemHeader text={item.variant.product.name} />
                    <PurchaseOrderItemDescription text={`Supplier code: ${item.supplierCode}`} />
                </Box>
                <Box>
                    <PurchaseOrderItemHeader text={item.quantity} />
                    <PurchaseOrderItemDescription text='Ordered' />
                </Box>
            </Wrapper>
            <Box column gap={20} flex={1}>
                {form.values.receivedItems.map((_, index) => (
                    <ItemUnitDetails
                        key={index}
                        unit={form.values.receivedItems[index]}
                        index={index}
                        onUnitChange={updateUnit}
                        deleteUnit={deleteUnit}
                    />
                ))}
                <FormTextarea label='Comments' api={form.fields.notes} nullable />
            </Box>
            <Wrapper padding={20} row fullWidth justify='space-between' alignSelf='flex-end'>
                <Box>
                    <PurchaseOrderItemHeader text='Receive progress' />
                </Box>
                <ProgressWrapper>
                    <PurchaseOrderItemHeader
                        text={`${scannedQuantity + item.receivedQuantity}/${item.quantity} units`}
                    />
                    <Box row fullWidth height={8} border rounded={4} marginTop={4}>
                        <RangeBar
                            rounded={4}
                            height={8}
                            minWidth={5}
                            width={`${((scannedQuantity + item.receivedQuantity) / item.quantity) * 100}%`}
                            bg={Color.AccentGreen}
                        />
                    </Box>
                </ProgressWrapper>
            </Wrapper>
        </Box>
    );
};

const Wrapper = styled(Box)`
    background-color: ${Color.BackgroundSubtleNeutral};
`;

const ProgressWrapper = styled(Box)`
    min-width: 120px;
    text-align: right;
`;

const progressBar = (width: string) => keyframes`
    0% { width: 0; }
    100% { width: ${width}; }
`;

const RangeBar = styled(Box)<{ width: string }>`
    animation: ${props => progressBar(props.width)} 1.5s ease;
`;
