import React, { FC } from 'react';

import { emptyAdminInvoiceFilters, useInvoices } from '@hofy/api-admin';
import { UUID } from '@hofy/global';

import { InvoicesTable } from '../../../components/domain/invoices/InvoicesTable';

interface OrganizationInvoicesTableProps {
    organizationId: UUID;
    search: string;
    onEntryClick(id: UUID): void;
}

export const OrganizationInvoicesTable: FC<OrganizationInvoicesTableProps> = ({
    organizationId,
    onEntryClick,
    search,
}) => {
    const { invoices, isFetchingNextPage, isLoading, hasNextPage, fetchNextPage } = useInvoices({
        ...emptyAdminInvoiceFilters,
        organization: [organizationId],
        search: search,
    });

    return (
        <InvoicesTable
            infinityScroll={{
                hasMore: hasNextPage,
                isLoading: isLoading,
                isLoadingMore: isFetchingNextPage,
                loadMore: fetchNextPage,
            }}
            invoices={invoices}
            onEntryClick={onEntryClick}
        />
    );
};
