import { autoUpdate, type ExtendedRefs, type ReferenceType, useFloating } from '@floating-ui/react';
import { isNil } from 'lodash-es';
import { useMemo, useState } from 'react';

import { useIsDisabled } from '../../../contexts';
import { InteractiveList } from '../types/InteractiveListTypes';
import { useA11yListInteractions } from './a11y/useA11yListInteractions';
import { dropdownMiddleware } from './dropdownMiddleware';

export const useInteractiveList = <T>({
    value,
    onChange,
    nullable,
    options,
    toText,
    toLabel,
    toSelectedLabel,
    toKey,
    disabled: listDisabled,
    contentWidth,
    contentMaxHeight,
    placement = 'bottom',
}: InteractiveList<T>) => {
    const disabled = useIsDisabled(listDisabled);
    const [isOpen, setIsOpen] = useState(false);

    const selectedIndex = value === null ? -1 : options.indexOf(value!);

    const select = (value: T) => {
        setIsOpen(false);
        onChange(value);
    };

    const clear = () => {
        if (nullable) {
            onChange(null);
        }
    };

    const getLabel = (value: T) => {
        if (isNil(value)) {
            return null;
        }
        const text = toText(value!);
        if (toLabel) {
            return toLabel(value!);
        }
        return text;
    };

    const getSelectedLabel = (value: T) => {
        if (isNil(value)) {
            return null;
        }
        if (toSelectedLabel) {
            return toSelectedLabel(value!);
        }
        return getLabel(value);
    };

    const getKey = (value: T) => {
        if (toKey) {
            return toKey(value!);
        }
        return toText(value!);
    };

    const {
        refs,
        floatingStyles,
        context,
        placement: resultantPlacement,
    } = useFloating({
        placement,
        open: isOpen,
        onOpenChange: setIsOpen,
        whileElementsMounted: (reference, floating, update) =>
            autoUpdate(reference, floating, update, { elementResize: false }),
        middleware: dropdownMiddleware({ contentWidth, contentMaxHeight }),
    });

    const stringList = useMemo(() => options.map(toText), [options]);

    const {
        referenceProps,
        floatingProps,
        itemProps,

        activeIndex,
        listRef,
    } = useA11yListInteractions({
        context,
        stringList,
        disabled,
        onChange: select,
    });

    return {
        refs: refs as ExtendedRefs<ReferenceType>,
        context,
        isOpen,
        setIsOpen,
        activeIndex,
        selectedIndex,
        select,
        clear,
        getLabel,
        getSelectedLabel,
        getKey,
        referenceProps,
        floatingProps,
        itemProps,
        listRef,
        floatingStyles,
        disabled,
        resultantPlacement,
    };
};
