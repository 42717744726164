interface AppConfig {
    env: string;
    isAdminApp?: boolean;
    sentryDsn?: string;
    segmentWriteKey?: string;
    hofyUrl?: string;
    zendeskUrl?: string;
    isNetsuite?: boolean;
    zendeskSupportDepartment?: string;
    zendeskScriptKey?: string;
    zendeskMessagingAuthId?: string;
    hotjarSiteId?: number;
    slackClientId?: string;
    hofyDocumentsUrl?: string;
    privacyPolicyUrl?: string;
    recaptchaSiteKey?: string;
    deelAdminUrl?: string;
    docsUrl?: string;
}

export const supportLink = 'https://help.letsdeel.com/hc/en-gb/requests/new?ticket_form_id=360001676798';

export const baseUrl = `${window.location.protocol}//${window.location.host}`;

// @ts-expect-error: window.APP_CONFIG is provided by the app config (see
// ui-admin/configs)
export const APP_CONFIG: AppConfig = window.APP_CONFIG;

// global.GIT_HASH is provided by the build and injected in
// the app (see ui-admin/env-parser.cjs)
export const GIT_HASH: string = global.GIT_HASH;

export const isE2E = () => {
    return APP_CONFIG.env === 'e2e';
};

export const isLocal = () => {
    return APP_CONFIG.env === 'local';
};

export const isDev = () => {
    return APP_CONFIG.env === 'dev' || APP_CONFIG.env === 'local';
};

export const isProd = () => {
    return APP_CONFIG.env === 'prod';
};

window.GIT_HASH = GIT_HASH;
