import { Price } from '@hofy/global';

import { AddonRefDto } from './AddonRefDto';

export const BillingMethod = {
    Currency: 'currency' as const,
    Token: 'token' as const,
};

interface TokenUsage {
    addon: AddonRefDto;
    multiplier: string;
}

interface CurrencyServiceCost<T = Price> {
    billingMethod: typeof BillingMethod.Currency;
    fees: T;
}

interface TokenServiceCost {
    billingMethod: typeof BillingMethod.Token;
    tokenUsage: TokenUsage;
}

export type ServiceCostDto<T = Price> = CurrencyServiceCost<T> | TokenServiceCost;
