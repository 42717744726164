export const assignmentCollectionReasonI18n = {
    'item-assignment-collection-reason.off-boarding': 'User off-boarding',
    'item-assignment-collection-reason.ordered-by-mistake': 'Ordered by mistake',
    'item-assignment-collection-reason.damaged-in-transit': 'Damaged in transit',
    'item-assignment-collection-reason.defective': 'Defective',
    'item-assignment-collection-reason.cancellation-request': 'Request cancelled',
    'item-assignment-collection-reason.wrong-item-sent': 'Wrong item sent',
    'item-assignment-collection-reason.disposal': 'Disposal',
    'item-assignment-collection-reason.end-of-contract': 'End of contract',
    'item-assignment-collection-reason.collect-and-reuse': 'Store and reuse',
    'item-assignment-collection-reason.debt-collection': 'Debt Collection',
    'item-assignment-collection-reason.clearance': 'Clearance',
    'item-assignment-collection-reason.other': 'Other',
};
