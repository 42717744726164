import { ColorDye } from '@hofy/global';

export enum ItemLocation {
    WithUser = 'with_user',
    // TODO ITOP-737:
    // treat each warehouse type separately by using HofyWarehouseID,
    // OrganizationWarehouseID or ClearanceWarehouseID and remove
    // ItemLocationAtWarehouse/WarehouseID
    AtWarehouse = 'at_warehouse',
    HofyWarehouse = 'hofy_warehouse',
    OrganizationWarehouse = 'organization_warehouse',
    ClearanceWarehouse = 'clearance_warehouse',
    Supplier = 'supplier',
    WithCourier = 'with_courier',
    WriteOff = 'write_off',
}

export const itemLocationColor: Record<ItemLocation, ColorDye> = {
    [ItemLocation.WithUser]: 'green',
    [ItemLocation.AtWarehouse]: 'grey',
    [ItemLocation.HofyWarehouse]: 'purple',
    [ItemLocation.OrganizationWarehouse]: 'teal',
    [ItemLocation.ClearanceWarehouse]: 'magenta',
    [ItemLocation.Supplier]: 'orange',
    [ItemLocation.WithCourier]: 'blue',
    [ItemLocation.WriteOff]: 'grey',
};

const validRepairLocations = [ItemLocation.WithUser, ItemLocation.AtWarehouse];
export const isValidRepairLocation = (location: ItemLocation) => validRepairLocations.includes(location);
