import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { goodConditionGrades, ItemStatus } from '@hofy/api-shared';
import { Country, UUID } from '@hofy/global';

import { itemsService } from '../service/itemsService';
import { AdminItemFilter } from '../service/types/AdminItemFilter';
import { ItemDto } from '../service/types/ItemDto';
import { ItemsFilters } from '../service/types/ItemsFilters';
import { itemCacheKey } from './cacheKey';

export const useItems = (filters: ItemsFilters, pageSize = 50) => {
    const { data, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } = useInfiniteQuery({
        queryKey: [itemCacheKey, filters],
        queryFn: param =>
            itemsService.listItems(filters, {
                page: param.pageParam,
                pageSize,
            }),
        initialPageParam: 0,
        getNextPageParam: lastPage => (lastPage.hasNext ? lastPage.page + 1 : undefined),
    });

    const items: ItemDto[] = useMemo(() => {
        return data?.pages.flatMap(v => v.content) || [];
    }, [data]);

    return {
        items,
        isLoading,
        hasNextPage,
        isFetchingNextPage,
        fetchNextPage,
    };
};

export const useRedistributableItems = (
    warehouseId: UUID,
    organizationId: UUID,
    variantId: UUID,
    country: Country,
) => {
    const filters: ItemsFilters = {
        grade: goodConditionGrades,
        organization: [organizationId],
        variant: [variantId],
        warehouseId: warehouseId,
        redistributableIn: country,
        status: [ItemStatus.Active],
        filter: AdminItemFilter.Redistributable,
        search: null,
        category: [],
        location: [],
        ownership: null,
        withAddon: [],
        withoutAddon: [],
        code: null,
        serial: null,
    };

    const { data, isLoading } = useQuery({
        queryKey: [itemCacheKey, 'redistributable', filters],

        queryFn: () =>
            itemsService.listItems(filters, {
                page: 0,
                pageSize: 50,
            }),
    });

    return {
        items: data?.content || [],
        isLoading,
    };
};

export const useExportItems = (filters: ItemsFilters) => {
    const exportItems = () => {
        itemsService.exportItems(filters);
    };
    return {
        exportItems,
    };
};
