import React, { FC } from 'react';

import { IssueDetailsDto, UpdateIssuePayload } from '@hofy/api-admin';
import { formatDate, nowISODate } from '@hofy/helpers';
import { FormDateInput, FormGridRow, FormSection, LabeledText, UseForm } from '@hofy/ui';

import { LinkType, ZendeskSection } from '../../../components/design/zendesk/ZendeskSection';
import { IssueDocumentsSection } from './IssueDocumentsSection';

interface IssueDetailsSectionProps {
    issue: IssueDetailsDto;
    form: UseForm<UpdateIssuePayload>;
    editable: boolean;
}

export const IssueDetailsSection: FC<IssueDetailsSectionProps> = ({ issue, form, editable }) => {
    return (
        <>
            <FormSection label='Issue details'>
                <FormGridRow columns={4}>
                    <LabeledText label='Issue number' content={issue.id} />
                    <LabeledText label='Created at' content={formatDate(issue.createdAt)} />
                    <LabeledText label='Description' content={issue.description} />
                    <LabeledText label='Public Id' content={issue.publicId} />
                    <LabeledText label='Issue Type' content={issue.issueType} />
                    <LabeledText label='Able to work?' content={issue.isAbleToWork ? 'Yes' : 'No'} />
                    {issue.softwareDescription && (
                        <LabeledText label='Software Description' content={issue.softwareDescription} />
                    )}
                    <FormDateInput
                        label='Resolved'
                        api={form.fields.resolvedOn}
                        maxDate={nowISODate()}
                        disabled={!editable}
                        nullable
                    />
                </FormGridRow>
            </FormSection>
            <FormSection label='Issue Documents'>
                <IssueDocumentsSection issue={issue} />
            </FormSection>
            <ZendeskSection linkType={LinkType.IssuePage} zendesk={issue.zendesk} />
        </>
    );
};
