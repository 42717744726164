import { useQuery } from '@tanstack/react-query';

import { assignmentService } from '@hofy/api-admin';
import { ServiceCostDto } from '@hofy/api-shared';
import { UUID } from '@hofy/global';

export const useReplacementCostQuery = (assignmentId: UUID, enabled: boolean) => {
    const { data, isLoading, isError } = useQuery<ServiceCostDto>({
        queryKey: ['replacementCost', assignmentId],
        queryFn: () => assignmentService.replacementCost(assignmentId),
        enabled,
        staleTime: Infinity,
    });

    return {
        data,
        isLoading,
        isError,
    };
};
