import React, { FC } from 'react';

import { StorageAssignmentUnionDto } from '@hofy/api-admin';
import {
    storageAssignmentsStatusColors,
    StorageAssignmentStatus,
    useTrStorageAssignmentStatus,
} from '@hofy/api-shared';
import { DateString, DateTimeString } from '@hofy/global';
import { Badge } from '@hofy/ui';

interface StorageAssignmentStatusChipProps {
    status: StorageAssignmentStatus;
}

export const StorageAssignmentStatusChip: FC<StorageAssignmentStatusChipProps> = ({ status }) => {
    const trStatus = useTrStorageAssignmentStatus();
    return <Badge label={trStatus(status)} color={storageAssignmentsStatusColors[status]} />;
};

export const storageAssignmentStatusDate = (v: StorageAssignmentUnionDto): DateString | DateTimeString => {
    switch (v.status) {
        case StorageAssignmentStatus.CollectionCancelled:
            return v.collectionCancelledAt;
        case StorageAssignmentStatus.CollectionPending:
            return v.collectionPendingAt;
        case StorageAssignmentStatus.CollectionWithCourier:
            return v.collectionWithCourierOn;
        case StorageAssignmentStatus.AtWarehouse:
            return v.atWarehouseOn;
        case StorageAssignmentStatus.DeliveryPending:
            return v.deliveryPendingAt;
        case StorageAssignmentStatus.DeliveryWithCourier:
            return v.deliveryWithCourierOn;
        case StorageAssignmentStatus.Delivered:
            return v.deliveredOn;
        case StorageAssignmentStatus.StorageCancelled:
            return v.storageCancelledAt;
    }
};
