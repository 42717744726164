export const storageAssignmentI18n = {
    'storage-assignment-status.collection-cancelled': 'Cancelled',
    'storage-assignment-status.collection-pending': 'Collection pending',
    'storage-assignment-status.collection-with-courier': 'Collection with courier',
    'storage-assignment-status.at-warehouse': 'At warehouse',
    'storage-assignment-status.storage-cancelled': 'Storage cancelled',
    'storage-assignment-status.delivery-pending': 'Delivery pending',
    'storage-assignment-status.delivery-with-courier': 'Delivery with courier',
    'storage-assignment-status.delivered': 'Delivered',
};
