export const invoiceErrorI18n = {
    'api-error.period-calculation.title': 'Cannot calculate revenue recognition period',
    'api-error.period-calculation.message': 'Cannot perform this operation',
    'api-error.invalid-token.title': 'Invalid token',
    'api-error.invalid-token.message': 'Cannot perform this operation',
    'api-error.tax-not-computed.title': 'Tax not computed for all lines',
    'api-error.tax-not-computed.message': 'Cannot perform this operation',
    'api-error.incorrect-two-invoice.title': 'Incorrect two invoice error',
    'api-error.incorrect-two-invoice.message': 'Cannot perform this operation',
    'api-error.installment-discrepancies.title': 'Installment discrepancies',
    'api-error.installment-discrepancies.message': 'Cannot perform this operation',
    'api-error.cannot-generate-revenue-recognition-for-future.title':
        'Cannot generate revenue recognition for dates in the future',
    'api-error.cannot-generate-revenue-recognition-for-future.message': 'Cannot perform this operation',
    'api-error.unsupported-topic.title': 'Unsupported topic',
    'api-error.unsupported-topic.message': 'Cannot perform this operation',
    'api-error.reference-not-loaded.title': 'No reference for invoice',
    'api-error.reference-not-loaded.message': 'Cannot perform this operation',
    'api-error.no-files-to-send.title': 'No files to send',
    'api-error.no-files-to-send.message': 'Cannot perform this operation',
    'api-error.two-api-invoices-not-sendable.title': 'Two api invoices cant be mailed',
    'api-error.two-api-invoices-not-sendable.message': 'Cannot perform this operation',
    'api-error.billing-entity-without-organization.title': 'Billing entity without required organization',
    'api-error.billing-entity-without-organization.message': 'Cannot perform this operation',
    'api-error.invoice-entry-read-only.title': 'Invoice entry is readonly',
    'api-error.invoice-entry-read-only.message': 'Cannot perform this operation',
    'api-error.invoice-entry-period-required.title': 'Invoice entry period required',
    'api-error.invoice-entry-period-required.message': 'Cannot perform this operation',
    'api-error.invoice-entry-period-not-supported.title': 'Invoice entry period not supported',
    'api-error.invoice-entry-period-not-supported.message': 'Cannot perform this operation',
    'api-error.invoice-entry-product-category-required.title': 'Invoice entry product category required',
    'api-error.invoice-entry-product-category-required.message': 'Cannot perform this operation',
    'api-error.invoice-entry-type-not-valid.title': 'Invoice entry type not valid',
    'api-error.invoice-entry-type-not-valid.message': 'Cannot perform this operation',
    'api-error.invoice-entry-update-not-supported.title': 'Invoice entry update not supported',
    'api-error.invoice-entry-update-not-supported.message': 'Cannot perform this operation',
    'api-error.delete-partner-invoice-entry-not-allowed.title': 'Partner invoice entry cannot be deleted',
    'api-error.delete-partner-invoice-entry-not-allowed.message': 'Cannot perform this operation',
    'api-error.delete-readonly-entry-not-allowed.title': 'Readonly entry cannot be deleted',
    'api-error.delete-readonly-entry-not-allowed.message': 'Cannot perform this operation',
    'api-error.tax-prediction-not-possible.title': 'Tax prediction not possible',
    'api-error.tax-prediction-not-possible.message': 'Cannot perform this operation',
    'api-error.address-update-invalid-tax-status.title': 'Cannot update address with reported tax',
    'api-error.address-update-invalid-tax-status.message': 'Cannot perform this operation',
    'api-error.delete-store-and-reuse-invoice-entry.title':
        'Cannot delete rental store and reuse invoice entry',
    'api-error.delete-store-and-reuse-invoice-entry.message': 'Cannot perform this operation',
    'api-error.different-billing-entity-currency.title': 'Different billing entity currency',
    'api-error.different-billing-entity-currency.message': 'Cannot perform this operation',
    'api-error.billing-entity-not-active.title': 'Billing entity not active',
    'api-error.billing-entity-not-active.message': 'Cannot perform this operation',
    'api-error.invoice-entry-report-max-rows.title': 'Report max rows exceeded',
    'api-error.invoice-entry-report-max-rows.message': 'Cannot perform this operation',
    'api-error.invoice-line-amount-is-zero.title': 'Invoice line amount is zero',
    'api-error.invoice-line-amount-is-zero.message': 'Cannot perform this operation',
    'api-error.invoice-entry-filter-org-with-billing-entity.title':
        'Organization and billing entity specified',
    'api-error.invoice-entry-filter-org-with-billing-entity.message': 'Cannot perform this operation',
    'api-error.already-invoiced.title': 'Already invoiced',
    'api-error.already-invoiced.message': 'Cannot perform this operation',
    'api-error.already-has-transaction.title': 'Already has transaction',
    'api-error.already-has-transaction.message': 'Cannot perform this operation',
    'api-error.tax-computation-failed.title': 'Tax computation failed',
    'api-error.tax-computation-failed.message': 'Cannot perform this operation',
    'api-error.deel-api-invoices-not-sendable.title': 'Deel API invoices cannot be mailed',
    'api-error.deel-api-invoices-not-sendable.message': 'Cannot perform this operation',
    'api-error.invoice-entry-managed-by-deel.title': 'Invoice entry managed by Deel',
    'api-error.invoice-entry-managed-by-deel.message': 'Cannot perform this operation',
    'api-error.incorrect-deel-invoice.title': 'Incorrect Deel invoice status',
    'api-error.incorrect-deel-invoice.message': 'Invoice status must be submitted to Deel',
    'api-error.not-deel-api-invoice.title': 'Not Deel API invoice',
    'api-error.not-deel-api-invoice.message': 'Cannot perform this operation',
    'api-error.inconsistent-invoice-times.title': 'Inconsistent invoice times',
    'api-error.inconsistent-invoice-times.message': 'Cannot perform this operation',
    'api-error.incorrect-invoice-credit-request.title': 'Incorrect invoice credit request',
    'api-error.incorrect-invoice-credit-request.message': 'Existing credit request with different entry set',
    'api-error.empty-entry-set-for-credit-request.title': 'Empty entry set for credit request',
    'api-error.empty-entry-set-for-credit-request.message': 'Cannot perform this operation',
};
