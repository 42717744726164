import { ItemStatus } from '@hofy/api-shared';
import { PageRequest, PageResponse, stringifyUrl, UUID } from '@hofy/global';
import { downloadFileFromResponse } from '@hofy/helpers';
import { restClient } from '@hofy/rest';

import { ChangeItemStatusPayload } from './types/ChangeItemStatusPayload';
import { ChangeOwnershipPayload } from './types/ChangeOwnershipPayload';
import { ItemCodesPayload } from './types/CreateItemCodePayload';
import { CreateItemPayload } from './types/CreateItemPayload';
import { ItemCodeDto } from './types/ItemCodeDto';
import { ItemCostDto } from './types/ItemCostDto';
import { ItemDto } from './types/ItemDto';
import { ItemReplacementBackToStockPayload } from './types/ItemReplacementBackToStockPayload';
import { ItemReplacementWriteOffPayload } from './types/ItemReplacementWriteOffPayload';
import { ItemsFilters } from './types/ItemsFilters';
import { UpdateItemPayload } from './types/UpdateItemPayload';

class ItemsService {
    public getItemCode = (code: string): Promise<ItemCodeDto> => {
        return restClient.getJson(`/api/admin/item-codes/${code}`);
    };

    public updateItemCodes = async (id: UUID, payload: ItemCodesPayload): Promise<void> => {
        return await restClient.put(`/api/admin/items/${id}/codes`, payload);
    };

    public getItem = (id: UUID): Promise<ItemDto> => {
        return this.getItemByCode(`#${id}`).then(t => t!);
    };

    public getItemByCode = (code: string): Promise<ItemDto | null> => {
        return restClient.getJson(`/api/admin/items/${encodeURIComponent(code)}`);
    };

    public updateItem = async (id: UUID, payload: UpdateItemPayload): Promise<void> => {
        return restClient.put(`/api/admin/items/${id}`, payload);
    };

    public listItems = async (filters: ItemsFilters, page: PageRequest): Promise<PageResponse<ItemDto>> => {
        return restClient.getJson<PageResponse<ItemDto>>(
            stringifyUrl({
                url: `/api/admin/items`,
                query: {
                    ...filters,
                    page: page.page,
                    pageSize: page.pageSize,
                },
            }),
        );
    };

    public exportItems = async (filters: ItemsFilters): Promise<void> => {
        return restClient
            .downloadFile(
                stringifyUrl({
                    url: `/api/admin/items/export`,
                    query: {
                        ...filters,
                    },
                }),
            )
            .then(downloadFileFromResponse);
    };

    public createItem = async (payload: CreateItemPayload): Promise<number> => {
        return restClient
            .postJson<{
                id: number;
            }>('/api/admin/items', payload)
            .then(r => r.id);
    };

    public changeStatus = async (id: UUID, payload: ChangeItemStatusPayload): Promise<void> => {
        return restClient.post(`/api/admin/items/${id}/status`, payload);
    };
    public updateReplacementResultBackToStock = async (
        id: UUID,
        payload: ItemReplacementBackToStockPayload,
    ): Promise<void> => {
        return restClient.post(`/api/admin/items/${id}/replacement/back-to-stock`, payload);
    };
    public updateReplacementResultWriteOff = async (
        id: UUID,
        payload: ItemReplacementWriteOffPayload,
    ): Promise<void> => {
        return restClient.post(`/api/admin/items/${id}/replacement/write-off`, payload);
    };
    public changeOwnership = async (id: UUID, payload: ChangeOwnershipPayload): Promise<void> => {
        return restClient.post(`/api/admin/items/${id}/ownership`, payload);
    };

    public unassignSubContract = (itemId: UUID): Promise<void> => {
        return restClient.put(`/api/admin/items/${itemId}/unassign-sub-contract`);
    };

    public getItemEstimatedValue = (variantId: UUID, warehouseId: UUID): Promise<ItemCostDto> => {
        return restClient.getJson(
            stringifyUrl({
                url: `/api/admin/items/variants/${variantId}/cost`,
                query: {
                    warehouseId: warehouseId,
                },
            }),
        );
    };

    public getItemAllowedStatuses = (itemId: UUID): Promise<ItemStatus[]> => {
        return restClient.getJson(`/api/admin/items/${itemId}/allowed-statuses`);
    };
}

export const itemsService = new ItemsService();
