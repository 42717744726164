import type { Placement } from '@floating-ui/react';
import { motion, type MotionStyle } from 'framer-motion';
import React, { ElementRef, forwardRef, ReactNode } from 'react';
import styled from 'styled-components';

import { Color, ZIndex } from '@hofy/theme';

import { placementToOrigin } from '../../../helpers/Css';
import { Box, BoxProps } from '../../base';

export interface DropdownContentProps extends BoxProps {
    children?: ReactNode;
    style?: MotionStyle;
    floatingProps?: any;
    placement: Placement;
}

export const DropdownContent = forwardRef<ElementRef<'div'>, DropdownContentProps>(
    ({ children, style, floatingProps, placement, ...rest }, ref) => {
        return (
            <WrapperBox column ref={ref} style={style} {...floatingProps} data-dropdown-content>
                <Box
                    as={motion.div}
                    initial={{ scale: 0.9, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    exit={{ scale: 0.9, opacity: 0 }}
                    transition={{ duration: 0.15 }}
                    style={placementToOrigin(placement)}
                    bg={Color.BackgroundDefault}
                    rounded={8}
                    elevation='+3'
                    data-test-key='dropdown-content'
                    {...rest}
                >
                    {children}
                </Box>
            </WrapperBox>
        );
    },
);

const WrapperBox = styled(Box)`
    z-index: ${ZIndex.Dropdown};
`;
