import React, { FC, useState } from 'react';

import { useBillingEntityQuery } from '@hofy/api-admin';
import { Permission } from '@hofy/api-shared';
import { MoreMenu, Overlay } from '@hofy/common';
import { isProd } from '@hofy/config';
import { UUID } from '@hofy/global';
import { usePermission } from '@hofy/permission';
import { Color } from '@hofy/theme';
import { Box, ConfirmModal, Heading3, Modals, PageHeader, Skeleton, Tab, Tabs } from '@hofy/ui';

import { DeelBatch } from '../../../../components/design/deel/DeelBatch';
import { useSyncBillingEntityInvoicesToDeel } from '../../../../store/invoices/useSyncBillingEntityInvoicesToDeel';
import { useSyncBillingEntityTwoPayments } from '../../../../store/invoices/useSyncBillingEntityTwoPayments';
import {
    allBillingEntityTabs,
    BillingEntityTab,
} from '../../../../store/invoicing/billingEntities/types/BillingEntityTab';
import { useAdminAccountingSyncBillingEntity } from '../../../../store/invoicing/billingEntities/useAdminAccountingSyncBillingEntity';
import { useDeleteTwoRejectedRepaymentPlans } from '../../../../store/invoicing/billingEntities/useDeleteTwoRejectedRepaymentPlans';
import { useTrBillingEntityTab } from '../../../../store/invoicing/billingEntities/useTrBillingEntityTab';
import { useUpdateCredit } from '../../../../store/invoicing/billingEntities/useUpdateCredit';
import { GenerateInvoicesModal } from '../../invoices/components/GenerateInvoicesModal';
import { CollectBillingEntityPaymentsModal } from '../components/CollectBillingEntityPaymentsModal';
import { RetryMandateVerificationModal } from '../components/RetryMandateVerificationModal';
import { TriggerTwoInvoicingModal } from '../components/TriggerTwoInvoicingModal';
import { ForkBillingEntityModal } from '../createUpdateBillingEntityOverlay/ForkBillingEntityModal';
import { UpdateCurrencyModal } from '../createUpdateBillingEntityOverlay/UpdateCurrencyModal';
import { BillingEntityDetails } from './BillingEntityDetails';
import { BillingEntityInvoicesTable } from './BillingEntityInvoicesTable';

interface BillingEntityDetailsOverlayProps {
    billingEntityId: UUID;
    billingEntityTab: BillingEntityTab;
    onChangeTab(tab: BillingEntityTab): void;
    onInvoiceClick(invoiceId: UUID): void;
    onUpdateBillingEntity(id: UUID): void;
    onClose(): void;
}

enum BillingEntityModal {
    GenerateInvoices,
    CollectPayments,
    RetryMandateVerification,
    DeleteRejectedPaymentPlans,
    TriggerTwoInvoicing,
    UpdateCurrency,
}

export const BillingEntityDetailsOverlay: FC<BillingEntityDetailsOverlayProps> = ({
    billingEntityId,
    billingEntityTab,
    onChangeTab,
    onInvoiceClick,
    onUpdateBillingEntity,
    onClose,
}) => {
    const [modal, setModal] = useState<BillingEntityModal>();
    const closeModal = () => setModal(undefined);

    const { hasPermission } = usePermission();
    const { accountingSyncBillingEntity } = useAdminAccountingSyncBillingEntity(billingEntityId);
    const { updateCredit } = useUpdateCredit(billingEntityId);
    const { syncTwoPayments } = useSyncBillingEntityTwoPayments(billingEntityId);
    const { deleteRejectedPlans } = useDeleteTwoRejectedRepaymentPlans(billingEntityId);
    const { syncBillingEntityInvoicesToDeel } = useSyncBillingEntityInvoicesToDeel(billingEntityId);
    const trTab = useTrBillingEntityTab();

    const { billingEntity, isLoading: isLoadingBillingEntity } = useBillingEntityQuery(billingEntityId);

    const forkRequired = billingEntity?.hasInvoiceEntries;
    const canUpdateCurrency =
        (!forkRequired && hasPermission(Permission.AdminOrganizationUpdateFinancialSettings)) ||
        (forkRequired && hasPermission(Permission.AdminOrganizationForkBillingEntity));
    const canTriggerTwoInvoicing =
        hasPermission(Permission.AdminInvoicesGenerate) && !isProd() && billingEntity?.twoApiEnabled;

    if (isLoadingBillingEntity) {
        return (
            <Overlay column flex='auto' bg={Color.BackgroundDefault}>
                <Skeleton />
            </Overlay>
        );
    }

    const renderContent = () => {
        switch (billingEntityTab) {
            case BillingEntityTab.Details:
                return <BillingEntityDetails billingEntityId={billingEntityId} />;
            case BillingEntityTab.Invoices:
                return (
                    <BillingEntityInvoicesTable
                        billingEntityId={billingEntityId}
                        onInvoiceClick={onInvoiceClick}
                    />
                );
        }
    };

    const moreMenu = (
        <MoreMenu
            items={[
                {
                    action: () => setModal(BillingEntityModal.GenerateInvoices),
                    label: 'Generate invoices',
                    visible: hasPermission(Permission.AdminInvoicesGenerate),
                },
                {
                    action: () => setModal(BillingEntityModal.CollectPayments),
                    label: 'Collect payments',
                    visible: hasPermission(Permission.AdminBillingEntityCollectPayments),
                },
                {
                    action: () => setModal(BillingEntityModal.RetryMandateVerification),
                    label: 'Retry mandate verification',
                    visible: hasPermission(Permission.AdminBillingEntityCollectPayments),
                },
                {
                    action: accountingSyncBillingEntity,
                    label: 'Sync to Netsuite',
                    visible: hasPermission(Permission.AdminAccountingNonTransactionalSync),
                },
                {
                    action: updateCredit,
                    label: 'Update Two credit',
                    visible: hasPermission(Permission.AdminOrganizationUpdateFinancialSettings),
                },
                {
                    action: syncTwoPayments,
                    label: 'Sync Two payments',
                    visible: hasPermission(Permission.AdminAccountingTransactionalSync),
                },
                {
                    action: () => onUpdateBillingEntity(billingEntityId),
                    label: 'Edit',
                    visible: hasPermission(Permission.AdminOrganizationUpdateFinancialSettings),
                },
                {
                    action: () => setModal(BillingEntityModal.UpdateCurrency),
                    label: 'Update currency',
                    visible: canUpdateCurrency,
                },
                {
                    action: () => setModal(BillingEntityModal.TriggerTwoInvoicing),
                    label: 'Trigger Two invoicing',
                    visible: canTriggerTwoInvoicing,
                },
                {
                    action: () => setModal(BillingEntityModal.DeleteRejectedPaymentPlans),
                    label: 'Delete rejected Two plans',
                    visible: hasPermission(Permission.AdminRepaymentPlanManage),
                },
                {
                    action: syncBillingEntityInvoicesToDeel,
                    label: 'Sync invoices to Deel',
                    visible: hasPermission(Permission.AdminAccountingTransactionalSync),
                },
            ]}
        />
    );

    return (
        <Overlay column flex='auto' bg={Color.BackgroundDefault}>
            <PageHeader
                leftSlot={!!billingEntity?.deel.id && <DeelBatch />}
                title={<Heading3>{billingEntity?.name || ''}</Heading3>}
                rightSlot={moreMenu}
                tabsSlot={
                    <Tabs active={billingEntityTab} onChange={onChangeTab}>
                        {allBillingEntityTabs.map(tab => (
                            <Tab key={tab} id={tab} label={trTab(tab)} />
                        ))}
                    </Tabs>
                }
            />
            <Box flex='auto' relative>
                {renderContent()}
            </Box>

            <Modals>
                {modal === BillingEntityModal.GenerateInvoices && (
                    <GenerateInvoicesModal billingEntityId={billingEntityId} onClose={closeModal} />
                )}
                {modal === BillingEntityModal.CollectPayments && (
                    <CollectBillingEntityPaymentsModal
                        billingEntityId={billingEntityId}
                        onClose={closeModal}
                    />
                )}
                {modal === BillingEntityModal.RetryMandateVerification && (
                    <RetryMandateVerificationModal billingEntityId={billingEntityId} onClose={closeModal} />
                )}
                {modal === BillingEntityModal.DeleteRejectedPaymentPlans && (
                    <ConfirmModal
                        keyPrefix='delete-rejected-plans'
                        onClose={closeModal}
                        onConfirm={deleteRejectedPlans}
                    />
                )}
                {modal === BillingEntityModal.TriggerTwoInvoicing && (
                    <TriggerTwoInvoicingModal billingEntityId={billingEntityId} onClose={closeModal} />
                )}
                {modal === BillingEntityModal.UpdateCurrency &&
                    (forkRequired ? (
                        <ForkBillingEntityModal billingEntity={billingEntity} onClose={onClose} />
                    ) : (
                        <UpdateCurrencyModal billingEntity={billingEntity!} onClose={onClose} />
                    ))}
            </Modals>
        </Overlay>
    );
};
