import { useQuery } from '@tanstack/react-query';

import { ClearanceWarehouseDetailsDto } from '../service/types/WarehouseDetailsDto';
import { warehouseService } from '../service/warehouseService';
import { warehouseCacheKey } from './warehousesCacheKey';

const emptyWarehouses: ClearanceWarehouseDetailsDto[] = [];

export const useClearanceWarehousesQuery = (enabled: boolean = true) => {
    const { isLoading, isError, data } = useQuery({
        queryKey: [warehouseCacheKey],
        queryFn: () => warehouseService.listClearanceWarehouses(),
        enabled,
    });

    return {
        isLoading,
        isError,
        data: data || emptyWarehouses,
        enabled,
    };
};
