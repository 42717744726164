import React, { FC, ReactNode } from 'react';
import { Route, type RouteChildrenProps, Switch, useHistory } from 'react-router-dom';

import { pathUuid, UUID } from '@hofy/global';
import { useGoBack } from '@hofy/hooks';
import { getEnumParam, getStringParam } from '@hofy/router';

import { AdminNavLink } from '../../../components/routing/AdminNavLink';
import { UserTab } from '../../../store/users/types/UserTab';
import { PeopleTab } from '../PeopleTab';
import { UsersPage } from './UsersPage';
import { UserTabsRouter } from './UserTabsRouter';

export const UsersRouter: FC = () => {
    const history = useHistory();
    const { goBack } = useGoBack();

    const handleOpenUser = (id: UUID) => {
        history.push(`${AdminNavLink.People}/${PeopleTab.Users}/${id}/${UserTab.Details}`);
    };

    const handleChangeUserTab = (id: UUID, tab: UserTab) => {
        history.replace(`${AdminNavLink.People}/${PeopleTab.Users}/${id}/${tab}`);
    };

    const handleOpenOrganization = (id: UUID) => {
        history.push(`${AdminNavLink.Organizations}/${id}/details`);
    };

    const handleOpenAssignment = (userId: UUID, assignmentId: UUID) => {
        history.push(`${AdminNavLink.People}/${PeopleTab.Users}/${userId}/assignments/${assignmentId}`);
    };

    const handleOpenUpdateUser = (userId: UUID) => {
        history.push(`${AdminNavLink.People}/${PeopleTab.Users}/${userId}/details/edit`);
    };

    const handleOpenEmail = (userId: UUID, emailId: UUID) => {
        history.push(`${AdminNavLink.People}/${PeopleTab.Users}/${userId}/emails/${emailId}`);
    };

    const handleCloseEmail = (userId: UUID) => {
        goBack(`${AdminNavLink.People}/${PeopleTab.Users}/${userId}/emails`);
    };

    const handleOpenMessage = (userId: UUID, messageId: UUID) => {
        history.push(`${AdminNavLink.People}/${PeopleTab.Users}/${userId}/messages/${messageId}`);
    };

    const handleCloseMessage = (userId: UUID) => {
        goBack(`${AdminNavLink.People}/${PeopleTab.Users}/${userId}/messages`);
    };

    const handleCloseUpdateUser = (userId: UUID) => {
        goBack(`${AdminNavLink.People}/${PeopleTab.Users}/${userId}/details`);
    };

    const handleOpenRequest = (requestId: UUID) => {
        history.push(`${AdminNavLink.Orders}/${requestId}`);
    };

    return (
        <Switch>
            <Route path={`${AdminNavLink.People}/${PeopleTab.Users}`} exact>
                <UsersPage onOpenUser={handleOpenUser} />
            </Route>
            <DetailsRoute path={`${AdminNavLink.People}/${PeopleTab.Users}/:userId(${pathUuid})/:userTab`}>
                {({ userId, userTab }) => (
                    <UserTabsRouter
                        detailsBasePath={`${AdminNavLink.People}/${PeopleTab.Users}/${userId}/details`}
                        ordersBasePath={`${AdminNavLink.People}/${PeopleTab.Users}/${userId}/assignments`}
                        emailsBasePath={`${AdminNavLink.People}/${PeopleTab.Users}/${userId}/emails`}
                        messageBasePath={`${AdminNavLink.People}/${PeopleTab.Users}/${userId}/messages`}
                        userId={userId}
                        userTab={userTab}
                        onChangeTab={tab => handleChangeUserTab(userId, tab)}
                        onOpenAssignment={assignmentId => handleOpenAssignment(userId, assignmentId)}
                        onOpenEmail={emailId => handleOpenEmail(userId, emailId)}
                        onOpenMessage={messageId => handleOpenMessage(userId, messageId)}
                        onOpenUpdateUser={() => handleOpenUpdateUser(userId)}
                        onOpenOrganization={handleOpenOrganization}
                        onCloseEmail={() => handleCloseEmail(userId)}
                        onCloseMessage={() => handleCloseMessage(userId)}
                        onCloseUpdateUser={() => handleCloseUpdateUser(userId)}
                        onOpenRequest={requestId => handleOpenRequest(requestId)}
                    />
                )}
            </DetailsRoute>
        </Switch>
    );
};

interface DetailsRouteProps {
    children(v: { userTab: UserTab; userId: UUID }): ReactNode;
    path?: string;
    exact?: boolean;
}

const DetailsRoute: FC<DetailsRouteProps> = ({ children, ...props }) => {
    const renderChildren = (props: RouteChildrenProps) => {
        const userId = getStringParam(props.match?.params || {}, 'userId');
        const userTab = getEnumParam<UserTab>(props.match?.params || {}, 'userTab', UserTab);

        if (userId && userTab) {
            return children({
                userId: userId as UUID,
                userTab,
            });
        }

        return null;
    };
    return <Route {...props}>{renderChildren}</Route>;
};
