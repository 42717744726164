import styled, { type CSSObject } from 'styled-components';

import { Color } from '@hofy/theme';

import { activeScaleCss, focusOutlineCss, innerFocusOutlineCss } from '../../helpers/Css';
import { styledAttrProps } from '../../helpers/StyledComponents';
import { Box, BoxProps } from './Box';

export interface PressableProps extends BoxProps {
    innerFocus?: boolean;
    inactive?: boolean;
    cursor?: CSSObject['cursor'];
    activeEffect?: boolean;
    hoverBg?: Color;
}

// Every prop inside `.attrs` will be removed from the component props in type
// but we want to keep `as` prop accessible that's why we have `as {}` here
export const Pressable = styled(Box).attrs(
    styledAttrProps({ as: 'button', type: 'button' }) as {},
)<PressableProps>`
    cursor: ${p => (p.inactive ? 'default' : p.cursor || 'pointer')};
    user-select: none;
    ${p => (p.innerFocus ? innerFocusOutlineCss : focusOutlineCss)}

    ${p => p.activeEffect && activeScaleCss}

    &:hover {
        ${p => p.hoverBg && `background-color: ${p.hoverBg};`}
    }
`;
