import React from 'react';

import { BillingMethod, ServiceCostDto } from '@hofy/api-shared';
import { currencySymbols } from '@hofy/global';
import { Color } from '@hofy/theme';
import { Alert, Paragraph3, Skeleton, SvgIcon } from '@hofy/ui';

interface ServiceCostProps {
    isLoading: boolean;
    isError: boolean;
    serviceCost: ServiceCostDto | undefined;
}

export const ServiceCost: React.FC<ServiceCostProps> = ({ isLoading, isError, serviceCost }) => {
    const renderServiceCostAlert = (cost: ServiceCostDto): string => {
        switch (cost.billingMethod) {
            case BillingMethod.Currency:
                return `Organisation will be charged ${currencySymbols[cost.fees.currency]}${
                    cost.fees.amount
                } for this service.`;
            case BillingMethod.Token:
                return `${cost.tokenUsage.multiplier} ${cost.tokenUsage.addon.name} token used for this service.`;
        }
    };

    if (isLoading && !isError) {
        return <Skeleton height={75} fullWidth radius={10} />;
    }

    if (!isError && !isLoading && serviceCost) {
        return (
            <Alert
                type='informative'
                icon={SvgIcon.DollarSign}
                description={
                    <Paragraph3 color={Color.ContentPrimary}>
                        {renderServiceCostAlert(serviceCost)}
                    </Paragraph3>
                }
            />
        );
    }

    return null;
};
