import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
    itPoliciesCacheKey,
    OrganizationDetailsDto,
    organizationService,
    subscriptionCacheKey,
} from '@hofy/api-admin';
import { useToast } from '@hofy/ui';

import { organizationsCacheKey } from './organizationsCacheKey';

export const useUnlinkOrganizationPartnership = (
    organization: OrganizationDetailsDto,
    onSuccess?: () => void,
) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: () => organizationService.unlinkOrganizationPartnership(organization.id),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [organizationsCacheKey, organization.id] });
            queryClient.invalidateQueries({ queryKey: [subscriptionCacheKey, organization.id] });
            queryClient.invalidateQueries({ queryKey: [itPoliciesCacheKey, organization.id] });

            showToast({
                message: `Organization ${organization.name} successfully unlinked from the partner organization`,
                type: 'positive',
            });
            onSuccess?.();
        },
    });

    return {
        unlinkOrganizationPartnership: mutation.mutate,
        isLoading: mutation.isPending,
    };
};
