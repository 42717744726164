import { type InputType } from '@storybook/csf';
import React from 'react';

import { Color } from '@hofy/theme';

import { SvgIcon } from '../components';
import { SlotPlaceholder } from './SlotPlaceholder';

export const storybookObjectControl = (options: object): InputType => {
    return {
        mapping: options,
        options: Object.keys(options),
        type: {
            name: 'other',
            value: 'string',
        },
        control: { type: 'select' },
    };
};

export const storybookIconControl = storybookObjectControl(SvgIcon);
export const storybookColorControl = storybookObjectControl(Color);

interface SlotControlOptions {
    name?: string;
    width?: number;
    height?: number;
}

export const storybookSlotControl = ({
    name = 'Children',
    height,
    width,
}: SlotControlOptions = {}): InputType => {
    const childrenOptions = {
        Placeholder: <SlotPlaceholder name={name} height={height} width={width} />,
        Empty: null,
    };

    return {
        mapping: childrenOptions,
        options: Object.keys(childrenOptions),
        type: {
            name: 'other',
            value: 'ReactNode',
        },
        control: {
            type: 'radio',
        },
    };
};
