import { noop } from 'lodash-es';
import React, { FC, useEffect } from 'react';

import {
    OrganizationAddonConfigDto,
    useHofyWarehouseQuery,
    useOrganizationAddonConfigsQuery,
} from '@hofy/api-admin';
import {
    AcquisitionType,
    AddonType,
    allRentalTerms,
    ContractSettingDto,
    PaymentSchema,
    RentalTerm,
} from '@hofy/api-shared';
import { SeparatedContainer } from '@hofy/common';
import { PaymentSchemaFormDropdown, RentalTermFormDropdown } from '@hofy/core';
import { Country, Currency, Price, UUID, zeroPrice } from '@hofy/global';
import { usePrice } from '@hofy/hooks';
import { ProductImage } from '@hofy/product';
import { Color } from '@hofy/theme';
import {
    ArrayField,
    Box,
    Button,
    ErrorStatePlaceholder,
    FormContainer,
    FormFieldRecord,
    FormGridRow,
    FormNumberInput,
    FormPriceInput,
    FormSection,
    LabeledInput,
    LabeledSelect,
    Paragraph3,
    Paragraph4,
    Skeleton,
    SvgIcon,
} from '@hofy/ui';

import { FormVariantSelect } from '../../../../components/domain/products/FormVariantSelect';
import {
    BulkStorageOrderFormItemData,
    emptyBulkStorageOrderItemFormData,
} from '../../../../store/bulkStorageOrders/types/BulkStorageOrderFormData';
import { useOrganizationDetailsQuery } from '../../../../store/organizations/useOrganizationDetailsQuery';
import { useProductPricing } from '../../../../store/products/useProductPricing';
import { useVariantDetailsMap, VariantDetailsMap } from '../hooks/useVariantDetailsMap';

interface BulkStorageItemsFormProps {
    items: ArrayField<BulkStorageOrderFormItemData>;
    warehouseId: UUID;
    orgId: UUID;
}

export const BulkStorageItemsForm: FC<BulkStorageItemsFormProps> = ({ items, warehouseId, orgId }) => {
    const { variantDetailsMap, isLoading } = useVariantDetailsMap();
    const { data: organization, isLoading: isOrganizationLoading } = useOrganizationDetailsQuery(orgId);
    const { data: addons, isLoading: areAddonConfigsLoading } = useOrganizationAddonConfigsQuery(orgId);
    const { data: hofyWarehouse, isLoading: isLoadingHofyWarehouse } = useHofyWarehouseQuery(warehouseId);

    const defaultBillingEntity = organization?.billingEntities.find(b => b.isDefault);
    const billingEntityForCountry = organization?.billingEntities.find(
        b => hofyWarehouse?.address?.country && b.countries.includes(hofyWarehouse.address.country),
    );

    const billingCurrency = billingEntityForCountry?.currency ?? defaultBillingEntity?.currency;

    useEffect(() => {
        if (items.fields.length === 0 && organization && billingCurrency) {
            items.add(emptyBulkStorageOrderItemFormData(billingCurrency));
        }
    }, [isOrganizationLoading]);

    if (isLoading || isOrganizationLoading || areAddonConfigsLoading || isLoadingHofyWarehouse) {
        return <Skeleton fullWidth height={200} />;
    }

    if (!organization || !variantDetailsMap || !hofyWarehouse || !addons || !billingCurrency) {
        return <ErrorStatePlaceholder />;
    }

    return (
        <FormSection label='Products' column>
            {!!items.fields.length && (
                <SeparatedContainer lined orientation='vertical' spacing={20}>
                    {items.fields.map(field => (
                        <ProductRowForm
                            key={field.key}
                            item={field.api}
                            orgId={orgId}
                            warehouseCountry={hofyWarehouse.address?.country ?? null}
                            variantsById={variantDetailsMap}
                            addons={addons}
                            onDeleteItem={() => items.remove(field.key)}
                            canBeDeleted={items.fields.length > 1}
                        />
                    ))}
                </SeparatedContainer>
            )}
            <Button
                label='Add new item'
                type='secondary'
                leftIcon={SvgIcon.Add}
                onClick={() => items.add(emptyBulkStorageOrderItemFormData(billingCurrency))}
            />
        </FormSection>
    );
};

interface ProductRowFormProps {
    orgId: UUID;
    item: FormFieldRecord<BulkStorageOrderFormItemData>;
    warehouseCountry: Country | null;
    variantsById: VariantDetailsMap;
    addons: OrganizationAddonConfigDto[];
    canBeDeleted: boolean;
    onDeleteItem(): void;
}

const ProductRowForm: FC<ProductRowFormProps> = ({
    orgId,
    item,
    warehouseCountry,
    variantsById,
    addons,
    canBeDeleted,
    onDeleteItem,
}) => {
    return (
        <Box gap={20} row flex='auto' bg={Color.BackgroundSurfaceBrand} padding={5} rounded>
            <Box gap={20} column>
                <ProductImage
                    image={item.variantId.value ? variantsById[item.variantId.value].imageUrl : null}
                    size={172}
                />
                <Button
                    type='secondary'
                    label='Remove'
                    leftIcon={SvgIcon.Trash}
                    onClick={onDeleteItem}
                    disabled={!canBeDeleted}
                />
                {item.variantId.value && item.contractSettings.value && (
                    <PricingDetails
                        organizationId={orgId}
                        customSourcingFee={item.customSourcingFee.value}
                        storageActivation={item.activationFee.value}
                        country={warehouseCountry}
                        variantId={item.variantId.value}
                        contractSettings={item.contractSettings.value}
                        quantity={item.expectedQuantity.value}
                    />
                )}
            </Box>
            <Box flex={1} gap={20} column>
                <FormSection label='Custom sourcing details' column>
                    <FormGridRow columns={2}>
                        <FormVariantSelect
                            label='Variant'
                            api={item.variantId}
                            placeholder='Select a variant'
                            isRequired
                            nullable
                        />
                        <LabeledInput
                            value={
                                item.variantId.value ? variantsById[item.variantId.value].productName : null
                            }
                            label='Product'
                            disabled
                            nullable
                            onChange={noop}
                        />
                        <FormNumberInput api={item.expectedQuantity} label='Expected quantity' />
                        <FormPriceInput api={item.customSourcingFee} label='Custom sourcing price' />
                    </FormGridRow>
                </FormSection>
                <FormSection label='Contract' column>
                    <FormGridRow columns={2}>
                        <LabeledSelect
                            label='Acquisition Type'
                            value={item.contractSettings.value.acquisitionType}
                            onChange={v => {
                                if (v === AcquisitionType.Rental) {
                                    item.contractSettings.setValue({
                                        acquisitionType: AcquisitionType.Rental,
                                        rentalTerm: RentalTerm.Rental36Months,
                                        paymentSchema: PaymentSchema.Upfront,
                                        servicePaymentSchema: PaymentSchema.Upfront,
                                        warrantyPaymentSchema: PaymentSchema.Upfront,

                                        serviceBundleId: item.contractSettings.value.serviceBundleId,
                                        warrantyBundleId: item.contractSettings.value.warrantyBundleId,
                                        serviceDuration: item.contractSettings.value.serviceDuration,
                                    });
                                }
                                if (v === AcquisitionType.Purchase) {
                                    item.contractSettings.setValue({
                                        acquisitionType: AcquisitionType.Purchase,
                                        rentalTerm: null,
                                        paymentSchema: null,
                                        servicePaymentSchema: PaymentSchema.Upfront,
                                        warrantyPaymentSchema: PaymentSchema.Upfront,

                                        serviceBundleId: item.contractSettings.value.serviceBundleId,
                                        warrantyBundleId: item.contractSettings.value.warrantyBundleId,
                                        serviceDuration: item.contractSettings.value.serviceDuration,
                                    });
                                }
                            }}
                            toText={v => v}
                            options={[AcquisitionType.Rental, AcquisitionType.Purchase]}
                        />
                        <RentalTermFormDropdown
                            onChange={rentalTerm => {
                                if (item.contractSettings.value.acquisitionType === AcquisitionType.Rental) {
                                    item.contractSettings.setValue({
                                        ...item.contractSettings.value,
                                        rentalTerm: rentalTerm,
                                    });
                                }
                            }}
                            value={item.contractSettings.value.rentalTerm ?? RentalTerm.Rental36Months}
                            label='Rental duration'
                            disabled={item.contractSettings.value.acquisitionType !== AcquisitionType.Rental}
                            allowedRentalTerms={allRentalTerms}
                            availableRentalTerms={allRentalTerms}
                            width='100%'
                        />
                        <PaymentSchemaFormDropdown
                            value={item.contractSettings.value.paymentSchema ?? PaymentSchema.Upfront}
                            onChange={v => {
                                if (item.contractSettings.value.acquisitionType === AcquisitionType.Rental) {
                                    item.contractSettings.setValue({
                                        ...item.contractSettings.value,
                                        paymentSchema: v,
                                        servicePaymentSchema: v,
                                        warrantyPaymentSchema: v,
                                    });
                                }
                            }}
                            rentalTerm={item.contractSettings.value.rentalTerm}
                            allowedBillingFrequencies={[PaymentSchema.Upfront]}
                            addonsEnabled={false}
                            width={250}
                            disabled={item.contractSettings.value.acquisitionType !== AcquisitionType.Rental}
                        />
                        <LabeledSelect
                            options={addons.filter(c => c.type === AddonType.Service)}
                            toText={a => a.name}
                            value={addons.find(a => a.id === item.contractSettings.value.serviceBundleId)}
                            onChange={serviceAddon => {
                                if (item.contractSettings.value.acquisitionType === AcquisitionType.Reuse) {
                                    return;
                                }
                                item.contractSettings.setValue({
                                    ...item.contractSettings.value,
                                    serviceBundleId: serviceAddon?.id ?? null,
                                    serviceDuration: serviceAddon?.config.enrollmentDuration ?? null,
                                });
                            }}
                            label='Service addon'
                        />
                        <LabeledSelect
                            options={[0, 6, 12, 24, 36]}
                            toText={a => a + ''}
                            value={item.contractSettings.value.serviceDuration ?? 0}
                            onChange={duration => {
                                if (item.contractSettings.value.acquisitionType === AcquisitionType.Reuse) {
                                    return;
                                }
                                item.contractSettings.setValue({
                                    ...item.contractSettings.value,
                                    serviceDuration: duration,
                                });
                            }}
                            label='Service duration'
                        />
                        <LabeledSelect
                            options={addons.filter(c => c.type === AddonType.Warranty)}
                            toText={a => a.name}
                            value={addons.find(a => a.id === item.contractSettings.value.warrantyBundleId)}
                            onChange={serviceAddon => {
                                if (item.contractSettings.value.acquisitionType === AcquisitionType.Reuse) {
                                    return;
                                }
                                item.contractSettings.setValue({
                                    ...item.contractSettings.value,
                                    warrantyBundleId: serviceAddon?.id ?? null,
                                });
                            }}
                            label='Warranty addon'
                        />
                    </FormGridRow>
                </FormSection>
                <FormSection label='Storage' column>
                    <FormGridRow columns={2}>
                        <FormNumberInput api={item.gracePeriodInMonths} label='Storage grace period' />
                        <FormPriceInput api={item.activationFee} label='Storage activation fee' />
                        <FormPriceInput
                            api={item.storageFee}
                            label='Storage monthly fee (after grace period)'
                        />
                    </FormGridRow>
                </FormSection>
            </Box>
        </Box>
    );
};

interface BasicDataFormProps {
    organizationId: UUID;
    country: Country | null;
    variantId: UUID;
    contractSettings: ContractSettingDto | null;

    customSourcingFee: Price;
    storageActivation: Price;
    quantity: number;
}

const PricingDetails: FC<BasicDataFormProps> = ({
    organizationId,
    country,
    variantId,
    contractSettings,
    customSourcingFee,
    storageActivation,
    quantity,
}) => {
    const { data, isLoading } = useProductPricing({
        organizationId: organizationId,
        country: country,
        teamId: null,
        variantId: variantId,
        contractSettings: contractSettings,
    });
    const { formatPrice, add, multiply } = usePrice();
    if (isLoading || !data) {
        return <Skeleton fullWidth height={200} />;
    }

    const storageOrderServicePrice = add(customSourcingFee, storageActivation);
    const totalPriceWithSourcingFees = add(data.totalAmount, storageOrderServicePrice);
    const totalItemInvoicePrice = multiply(totalPriceWithSourcingFees, quantity);

    return (
        <FormContainer marginVertical={10}>
            <Box row gap={10}>
                <Box column flex={1} gap={10}>
                    <Paragraph4 bold>Total per unit</Paragraph4>
                    <Paragraph3 bold>{`Total (unit x ${quantity})`}</Paragraph3>
                </Box>
                <Box column gap={10} alignItems='flex-end'>
                    <Paragraph4>{formatPrice(totalPriceWithSourcingFees)}</Paragraph4>
                    <Paragraph3 bold>{formatPrice(totalItemInvoicePrice)}</Paragraph3>
                </Box>
            </Box>
            <Paragraph3>Pricing details:</Paragraph3>
            <Box row gap={10}>
                <Box column flex={1} gap={10}>
                    <Paragraph4 bold>Equip. base price</Paragraph4>
                    <Paragraph4 bold>Country multiplier</Paragraph4>
                    <Paragraph4 bold>Country base price</Paragraph4>
                    <Paragraph4 bold>Config. price</Paragraph4>
                    <Paragraph4 bold>Service Addon price</Paragraph4>
                    <Paragraph4 bold>Warranty Addon price</Paragraph4>
                    <Paragraph4 bold>Storage order services</Paragraph4>
                </Box>
                <Box column gap={10} alignItems='flex-end'>
                    <Paragraph4>{formatPrice(data.basePrice)}</Paragraph4>
                    <Paragraph4>{data.countryMultiplier}</Paragraph4>
                    <Paragraph4>{formatPrice(data.countryPrice)}</Paragraph4>
                    <Paragraph4>{formatPrice(data.configFee)}</Paragraph4>
                    <Paragraph4>
                        {formatPrice(data.service?.totalPrice ?? zeroPrice(Currency.USD))}
                    </Paragraph4>
                    <Paragraph4>
                        {formatPrice(data.warranty?.totalPrice ?? zeroPrice(Currency.USD))}
                    </Paragraph4>
                    <Paragraph4>{formatPrice(storageOrderServicePrice)}</Paragraph4>
                </Box>
            </Box>
        </FormContainer>
    );
};
