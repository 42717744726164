export const taxStatusI18n = {
    'tax-status.computed': 'Computed',
    'tax-status.pending': 'Pending',
    'tax-status.error': 'Error',
    'tax-status.report-error': 'Report error',
    'tax-status.prediction': 'Prediction',
    'tax-status.reported': 'Reported',
    'tax-status.voided': 'Voided',
    'tax-status.void-error': 'Void error',
    'tax-status.refunded': 'Refunded',
    'tax-status.refund-error': 'Refund error',
};
