import { Currency, DateString, UUID } from '@hofy/global';
import { nowISODate } from '@hofy/helpers';
import { isBlank, isRequired, isRequiredIf, isValidHttpsURL, useForm, validator } from '@hofy/ui';

export interface PurchaseOrderHeaderFormData {
    purchaseOrderReference: string | null;
    purchaseOrderSupplierReference: string | null;
    supplierId: UUID | null;
    currency: Currency;
    toWarehouseId: UUID | null;
    orderedOn: DateString;
    estimatedReceiveOn: DateString | null;
    notes: string | null;
    shipmentId: UUID | null;
    toUserId: UUID | null;
    isDropship: boolean;
    courierReference: string | null;
    trackingLink: string | null;
}

export interface ValidatedPurchaseOrderHeaderData extends PurchaseOrderHeaderFormData {
    purchaseOrderReference: string;
    purchaseOrderSupplierReference: string;
    supplierId: UUID;
    estimatedReceiveOn: DateString;
    notes: string;
}

export const defaultPurchaseOrderCurrency = Currency.USD;
export const emptyPurchaseOrderHeaderFormData: PurchaseOrderHeaderFormData = {
    purchaseOrderReference: null,
    purchaseOrderSupplierReference: null,
    supplierId: null,
    currency: defaultPurchaseOrderCurrency,
    toWarehouseId: null,
    orderedOn: nowISODate(),
    estimatedReceiveOn: null,
    notes: null,
    shipmentId: null,
    toUserId: null,
    isDropship: false,
    courierReference: null,
    trackingLink: null,
};

export const usePurchaseOrderHeaderForm = (initialState: PurchaseOrderHeaderFormData) => {
    return useForm<PurchaseOrderHeaderFormData>({
        initial: initialState,
        validate: validator<PurchaseOrderHeaderFormData>({
            supplierId: isRequired('Supplier is required'),
            currency: isRequired('Currency is required'),
            estimatedReceiveOn: isRequired('Estimated delivery date is required'),
            orderedOn: isRequired('Ordered date is required'),
            toWarehouseId: isRequiredIf(s => !s.isDropship, 'Warehouse is required'),
            toUserId: isRequiredIf(s => s.isDropship, 'User is required'),
            purchaseOrderReference: isRequired('Purchase order reference is required'),
            courierReference: isRequiredIf(
                values => !isBlank(values.trackingLink),
                'Courier reference is required when tracking link is provided',
            ),
            trackingLink: [
                isValidHttpsURL('Invalid tracking link'),
                isRequiredIf(
                    values => !isBlank(values.courierReference),
                    'Tracking link is required when courier reference is provided',
                ),
            ],
        }),
        initialDeps: [initialState],
    });
};
